import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  essential = true;
  organiz = false;
  strategy = false;
  abm = false;
  performance = false;
  oper = false;
  duration = 500;
  constructor() {}

  ngOnInit(): void {
    this.calc();
  }

  async calc() {
    await this.first();
  }

  first() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.essential = false;
        this.organiz = true;
        this.second();
      }, this.duration);
    });
  }
  second() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.organiz = false;
        this.strategy = true;
        this.third();
      }, this.duration);
    });
  }

  third() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.strategy = false;
        this.abm = true;
        this.forth();
      }, this.duration);
    });
  }
  forth() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.abm = false;
        this.performance = true;
        this.fifth();
      }, this.duration);
    });
  }
  fifth() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.performance = false;
        this.oper = true;
        this.sixth();
      }, this.duration);
    });
  }
  sixth() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.oper = false;
      }, this.duration);
    });
  }
}
