import { Component, OnInit } from '@angular/core';
import { LocalService } from 'src/app/services/local.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FiredataService } from 'src/app/services/firedata.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  qDate = moment().format('DD/M/YYYY');

  f1 = [];
  f2 = [];
  f3 = [];
  f4 = [];
  f5 = [];
  s1 = [];
  s2 = [];
  s3 = [];
  s4 = [];
  s5 = [];
  t1 = [];
  t2 = [];
  t3 = [];
  t4 = [];
  t5 = [];
  fo1 = [];
  fo2 = [];
  fo3 = [];
  fo4 = [];
  fo5 = [];
  fi1 = [];
  fi2 = [];
  fi3 = [];
  fi4 = [];
  si1 = [];
  si2 = [];
  si3 = [];
  si4 = [];
  fbAnswers = [];
  linkdinAnswrs = [];
  firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fifthScore = 0;
  sixthScore = 0;
  totalScore = 0;
  qForm: any;
  date: any;
  modalRef: any;
  industriesfr = [
    'Automobile',
    'Nourriture et boisson',
    'Finance',
    'Éducation',
    'Hospitalité',
    'Médias-Divertissement',
    'Assurance',
    'Immobilier',
    'UUtilitaires-Énergie',
    'Secteur public',
    'Soins de santé',
    'Fabrication',
    'Technologie',
    'Télécoms',
    'Vente au détail',
    'Services professionnels',
    'Transport-Logistique',
    'autre',
  ];
  industriesgr = [
    'Αυτοκίνητο',
    'Τρόφιμα-Ποτά',
    'Χρηματοοικονoμικά',
    'Εκπαίδευση',
    'Φιλοξενία-Τουρισμός',
    'Μέσα Ενημέρωσης - Ψυχαγωγία',
    'Ασφαλιστικές Υπηρεσίες',
    'Ακίνητα',
    'Κοινωφελείς Οργανισμοί',
    'Ενέργεια',
    'Δημόσιος τομέας',
    'Φροντίδα & Υπηρεσίες υγείας',
    'Βιομηχανική Μεταποίηση & Κατασκευή',
    'Τεχνολογία',
    'Τηλεπικοινωνίες',
    'Λιανεμποριο',
    'Επαγγελματικές υπηρεσίες',
    'Μεταφορές-Αποθήκευση-Logistics',
    'άλλο',
  ];
  industriesen = [
    'Automotive',
    'Food and Beverage',
    'Finance',
    'Education',
    'Hospitality',
    'Media-Entertainment',
    'Insurance',
    'Real Estate',
    'Utilities-Energy',
    'Public Sector',
    'Healthcare',
    'Manufacturing',
    'Technology',
    'Telecoms',
    'Retail',
    'Professional Services',
    'Transport-Logistics',
    'other',
  ];
  industries = [];
  howlargesArr = [];
  howlongArr = [];
  lifecycleStages = [];
  growthGoals = [];
  marketingBudgets = [];

  howlargesArren = [
    '0-1€ M',
    '€1-5 M ',
    '€5-10 M ',
    '€10-50 M ',
    '€50+ M   ',
    'Not sure',
  ];
  howlargesArrgr = [
    '0-1€ εκ',
    '€1-5 εκ ',
    '€5-10 εκ ',
    '€10-50 εκ ',
    '€50+ εκ   ',
    'Δεν είμαι βέβαιος',
  ];
  howlargesArrfr = [
    '0-1€ M',
    '€1-5 M ',
    '€5-10 M ',
    '€10-50 M ',
    '€50+ M   ',
    'Je ne suis pas sûr',
  ];

  howlongArren = [
    '0-1 Week ',
    '1-4 Weeks ',
    '4-8 Weeks ',
    '8-12 Weeks',
    '12+ Weeks',
  ];
  howlongArrgr = [
    '0-1 Εβδομάδα ',
    '1-4 Εβδομάδες  ',
    '4-8 Εβδομάδες  ',
    '8-12 Εβδομάδες ',
    '12+ Εβδομάδες ',
  ];
  howlongArrfr = [
    '0-1 Semaine  ',
    '1-4 Semaines  ',
    '4-8 Semaines  ',
    '8-12 Semaines ',
    '12+ Semaines ',
  ];
  hownanyArr = ['1-10', '11-50', '51-100', '101-500', '500+'];
  lifecycleStagesen = [
    'START-UP: Focus on establishing customer base and proving business model ',
    'EARLY GROWTH: Focused on infrastructure, talent acquisition, steady growth and profitability ',
    'HIGH GROWTH: Aggressive growth goals potentially through expansion, partnerships, mergers and acquisitions',
    'MATURE: Focused on customer retention with conservative to moderate growth goals ',
    'DECLINING: Struggling to retain existing customer base and attract new leads',
  ];
  lifecycleStagesgr = [
    'START-UP: Εστίαση στη δημιουργία πελατειακής βάσης και στην απόδειξη του επιχειρηματικού μοντέλου ',
    'ΠΡΩΙΜΗ ΑΝΑΠΤΥΞΗ: Με επίκεντρο τις υποδομές, την απόκτηση ταλέντων, τη σταθερή ανάπτυξη και την κερδοφορία ',
    'ΥΨΗΛΗ ΑΝΑΠΤΥΞΗ: Επιθετικοί στόχοι ανάπτυξης δυνητικά μέσω επέκτασης, συνεργασιών, συγχωνεύσεων και εξαγορών',
    'ΩΡΙΜΟΤΗΤΑ: Επικεντρώνεται στη διατήρηση πελατών με συντηρητικούς έως μέτριους στόχους ανάπτυξης',
    'ΜΕΙΩΣΗ: Αγωνίζεται να διατηρήσει την υπάρχουσα πελατειακή βάση και να προσελκύσει νέους δυνητικούς πελάτες ',
  ];
  lifecycleStagesfr = [
    "START-UP : se concentrer sur l'établissement d'une base de clients et la démonstration d'un modèle commercial",
    "CROISSANCE PRÉCOCE : axé sur l'infrastructure, l'acquisition de talents, une croissance et une rentabilité régulières",
    "CROISSANCE ÉLEVÉE : objectifs de croissance agressifs, potentiellement via l'expansion, les partenariats, les fusions et les acquisitions.",
    'MATURE : axé sur la fidélisation de la clientèle avec des objectifs de croissance conservateurs à modérés',
    'EN DÉCLIN : difficulté à fidéliser la clientèle existante et à attirer de nouveaux prospects',
  ];
  marketingObjectives = ['', '', '', ''];
  growthGoalsen = [
    {
      label: 'Conservative (<5%) ',
      value: 'Conservative (<5) ',
    },
    {
      label: 'Moderately conservative (5-10%)',
      value: 'Moderately conservative (5-10)',
    },
    {
      label: 'Moderate (10-15%)',
      value: 'Moderate (10-15)',
    },
    {
      label: 'Moderate aggressive (15-20%)',
      value: 'Moderate aggressive (15-20)',
    },
    {
      label: 'Aggressive (>20%)',
      value: 'Aggressive (>20)',
    },
    {
      label: 'Not sure',
      value: 'Not sure',
    },
  ];
  growthGoalsgr = [
    {
      label: 'Συντηρητικός  (<5%) ',
      value: 'Συντηρητικός (<5) ',
    },
    {
      label: 'Μέτρια συντηρητικός (5-10%)',
      value: 'Μέτρια συντηρητικός (5-10)',
    },
    {
      label: 'Μέτριος  (10-15%)',
      value: 'Μέτριος  (10-15)',
    },
    {
      label: 'Μέτρια επιθετικός (15-20%)',
      value: 'Μέτρια επιθετικός (15-20)',
    },
    {
      label: 'Επιθετικός  (>20%)',
      value: 'Επιθετικός  (>20)',
    },
    {
      label: 'Δεν είμαι σίγουρος',
      value: 'Δεν είμαι σίγουρος',
    },
  ];
  growthGoalsfr = [
    {
      label: 'Conservateur  (<5%) ',
      value: 'Conservateur  (<5) ',
    },
    {
      label: 'Modérément conservateur (5-10%)',
      value: 'Modérément conservateur (5-10)',
    },
    {
      label: 'Modéré  (10-15%)',
      value: 'Modéré  (10-15)',
    },
    {
      label: 'Modérément agressif (15-20%)',
      value: 'Modérément agressif (15-20)',
    },
    {
      label: 'Agressif  (>20%)',
      value: 'Agressif  (>20)',
    },
    {
      label: 'Pas certain',
      value: 'Pas certain',
    },
  ];
  marketingBudgetsen = [
    {
      label: 'Conservative (<5%) ',
      value: 'Conservative (<5) ',
    },
    {
      label: 'Moderately conservative (5-10%)',
      value: 'Moderately conservative (5-10)',
    },
    {
      label: 'Moderate (10-15%)',
      value: 'Moderate (10-15)',
    },
    {
      label: 'Moderate aggressive (15-20%)',
      value: 'Moderate aggressive (15-20)',
    },
    {
      label: 'Aggressive (>20%)',
      value: 'Aggressive (>20)',
    },
    {
      label: 'Not sure',
      value: 'Not sure',
    },
  ];
  marketingBudgetsgr = [
    {
      label: 'Συντηρητικός  (<5%) ',
      value: 'Συντηρητικός  (<5) ',
    },
    {
      label: 'Μέτρια συντηρητικός  (5-10%)',
      value: 'Μέτρια συντηρητικός  (5-10)',
    },
    {
      label: 'Μέτριος  (10-15%)',
      value: 'Μέτριος  (10-15)',
    },
    {
      label: 'Μέτρια επιθετικός (15-20%)',
      value: 'Μέτρια επιθετικός (15-20)',
    },
    {
      label: 'Επιθετικός  (>20%)',
      value: 'Επιθετικός  (>20)',
    },
    {
      label: 'Δεν είμαι σίγουρος',
      value: 'Δεν είμαι σίγουρος',
    },
  ];
  marketingBudgetsfr = [
    {
      label: 'Conservateur  (<5%) ',
      value: 'Conservateur  (<5) ',
    },
    {
      label: 'Modérément conservateur (5-10%)',
      value: 'Modérément conservateur (5-10)',
    },
    {
      label: 'Modéré  (10-15%)',
      value: 'Modéré  (10-15)',
    },
    {
      label: 'Modérément agressif  (15-20%)',
      value: 'Modérément agressif  (15-20)',
    },
    {
      label: 'Agressif  (>20%)',
      value: 'Agressif  (>20)',
    },
    {
      label: 'Pas certain',
      value: 'Pas certain',
    },
  ];
  currentLanguage: string;
  subscription: Subscription;
  private body: any;
  constructor(
    private localData: LocalService,
    private fb: FormBuilder,
    private router: Router,
    private fireServ: FiredataService,
    public modalService: NgbModal,
    private http: HttpClient,
    private languageService: LanguageService
  ) {
    this.date = new Date();
    this.getAnswers();
    this.setLangs();

    this.firstScore = this.localData.firstScore;
    this.secondScore = this.localData.secondScore;
    this.thirdScore = this.localData.thirdScore;
    this.forthScore = this.localData.forthScore;
    this.fifthScore = this.localData.fifthScore;
    this.sixthScore = this.localData.sixthScore;
    this.totalScore =
      (this.firstScore +
        this.secondScore +
        this.thirdScore +
        this.forthScore +
        this.fifthScore +
        this.sixthScore) /
      6;

    if (this.totalScore === undefined) {
      this.totalScore = 0;
    }

    // mockdata
    // this.firstScore = 95;
    // this.secondScore = 95;
    // this.thirdScore = 93.75;
    // this.forthScore = 80;
    // this.fifthScore = 100;
    // this.sixthScore = 87.5;
    // this.totalScore = 93.88888;

    this.qForm = this.fb.group({
      fullname: ['', Validators.required],
      company: ['', Validators.required],
      role: ['', Validators.required],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^[a-z0-9._%+-]+@(?!yahoo|gmail|msn|hotmail|live)[a-z0-9.-]+\\.[a-z]{2,4}$'
          ),
        ],
      ],
      industry: ['', Validators.required],
      howLarge: ['', Validators.required],
      howLong: ['', Validators.required],
      howMany: ['', Validators.required],
      growthGoal: ['', Validators.required],
      budget: ['', Validators.required],
      lifecycle: ['', Validators.required],
      brand: ['', Validators.required],
      leads: ['', Validators.required],
      loyalty: ['', Validators.required],
      sales: ['', Validators.required],
      sxolia: [''],

      terms: [false, Validators.requiredTrue],
    });
  }

  setLangs() {
    if (this.currentLanguage === 'en') {
      this.industries = this.industriesen;
      this.howlargesArr = this.howlargesArren;
      this.howlongArr = this.howlongArren;
      this.lifecycleStages = this.lifecycleStagesen;
      this.growthGoals = this.growthGoalsen;
      this.marketingBudgets = this.marketingBudgetsen;
    }
    if (this.currentLanguage === 'el') {
      this.industries = this.industriesgr;
      this.howlargesArr = this.howlargesArrgr;
      this.howlongArr = this.howlongArrgr;
      this.lifecycleStages = this.lifecycleStagesgr;
      this.growthGoals = this.growthGoalsgr;
      this.marketingBudgets = this.marketingBudgetsgr;
    }
    if (this.currentLanguage === 'fr') {
      this.industries = this.industriesfr;
      this.howlargesArr = this.howlargesArrfr;
      this.howlongArr = this.howlongArrfr;
      this.lifecycleStages = this.lifecycleStagesfr;
      this.growthGoals = this.growthGoalsfr;
      this.marketingBudgets = this.marketingBudgetsfr;
    }
  }

  ngOnInit(): void {
    this.subscription = this.languageService.currentLanguage$.subscribe(
      (language) => {
        this.currentLanguage = language;
        this.setLangs();
        console.log(this.currentLanguage);
      }
    );
  }
  // / init

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  // (?!yahoo|gmail|msn|hotmail|live)
  // Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  getAnswers() {
    this.f1 = this.sortAndFillwithX(this.localData.f1, 6);
    this.f2 = this.sortAndFillwithX(this.localData.f2, 5);
    this.f3 = this.sortAndFillwithX(this.localData.f3, 2);
    this.f4 = this.sortAndFillwithX(this.localData.f4, 10);
    this.f5 = this.sortAndFillwithX(this.localData.f5, 6);

    this.s1 = this.sortAndFillwithX(this.localData.s1, 10);
    this.s2 = this.sortAndFillwithX(this.localData.s2, 10);
    this.s3 = this.sortAndFillwithX(this.localData.s3, 10);
    this.s4 = this.sortAndFillwithX(this.localData.s4, 10);
    this.s5 = this.sortAndFillwithX(this.localData.s5, 10);

    this.t1 = this.localData.t1;
    this.t2 = this.localData.t2;
    this.t3 = this.sortAndFillwithX(this.localData.t3, 10);
    this.t4 = this.sortAndFillwithX(this.localData.t4, 10);
    this.t5 = this.localData.t5;

    this.fo1 = this.sortAndFillwithX(this.localData.fo1, 10);
    this.fo2 = this.sortAndFillwithX(this.localData.fo2, 10);
    this.fo3 = this.sortAndFillwithX(this.localData.fo3, 10);
    this.fo4 = this.sortAndFillwithX(this.localData.fo4, 10);
    this.fo5 = this.sortAndFillwithX(this.localData.fo5, 10);

    this.fi1 = this.sortAndFillwithX(this.localData.fi1, 10);
    this.fi2 = this.sortAndFillwithX(this.localData.fi2, 10);
    this.fi3 = this.sortAndFillwithX(this.localData.fi3, 10);
    this.fi4 = this.sortAndFillwithX(this.localData.fi4, 10);

    this.si1 = this.sortAndFillwithX(this.localData.si1, 10);
    this.si2 = this.sortAndFillwithX(this.localData.si2, 10);
    this.si3 = this.sortAndFillwithX(this.localData.si3, 10);
    this.si4 = this.sortAndFillwithX(this.localData.si4, 10);
    this.fbAnswers = this.localData.fbAnswers;
    this.linkdinAnswrs = this.localData.linkdinAnswrs;
    if (this.fbAnswers === undefined) {
      this.fbAnswers = [];
    }
    if (this.linkdinAnswrs === undefined) {
      this.linkdinAnswrs = [];
    }
  }

  sortAndFillwithX(data, l) {
    const newArr = data.sort();
    const newerArr = [];
    for (let i = 0; i < l; i++) {
      const filter = newArr.filter((x) => x === i + 1);
      if (filter.length === 0) {
        newerArr.push('X');
      } else {
        newerArr.push(i + 1);
      }
    }
    return newerArr;
  }

  submitForm() {
    const formaValue = this.qForm.value;

    this.router.navigate(['/assessment/' + formaValue.phone]);

    // this.openModal();
    // const formaValue = this.qForm.value;

    // formaValue.replies = {
    //   f1: this.f1,
    //   f2: this.f2,
    //   f3: this.f3,
    //   f4: this.f4,
    //   f5: this.f5,
    //   s1: this.s1,
    //   s2: this.s2,
    //   s3: this.s3,
    //   s4: this.s4,
    //   s5: this.s5,
    //   t1: this.t1,
    //   t2: this.t2,
    //   t3: this.t3,
    //   t4: this.t4,
    //   t5: this.t5,
    //   fo1: this.fo1,
    //   fo2: this.fo2,
    //   fo3: this.fo3,
    //   fo4: this.fo4,
    //   fo5: this.fo5,
    //   fi1: this.fi1,
    //   fi2: this.fi2,
    //   fi3: this.fi3,
    //   fi4: this.fi4,
    //   si1: this.si1,
    //   si2: this.si2,
    //   si3: this.si3,
    //   si4: this.si4,
    //   fbAnswers: this.fbAnswers,
    //   linkdinAnswrs: this.linkdinAnswrs,
    // };
    // formaValue.date = this.date;
    // formaValue.qdate = this.qDate;
    // formaValue.firstScore = this.firstScore;
    // formaValue.secondScore = this.secondScore;
    // formaValue.thirdScore = this.thirdScore;
    // formaValue.forthScore = this.forthScore;
    // formaValue.fifthScore = this.fifthScore;
    // formaValue.sixthScore = this.sixthScore;
    // formaValue.totalScore = this.totalScore;
    // console.log(formaValue);
    // this.localData.company = formaValue.company;

    // formaValue.datelocale = formaValue.date.toLocaleString('el-GR');
    // this.fireServ.addSubscription(formaValue);
    // this.sendEmail(formaValue);
    // console.log(this.qForm.value);
    // const id = formaValue.phone;
    // setTimeout(() => {
    //   this.closeModal();
    //   this.router.navigate(['/assessment/' + id]);
    // }, 3000);
  }
  // / submit

  openModal() {
    this.modalRef = this.modalService.open(SpinnerComponent);
  }
  closeModal() {
    this.modalRef.close();
  }

  sendEmail(formaValue) {
    const url =
      'https://enzquiz.netlify.app/.netlify/functions/sendEmail?email=' +
      formaValue.email +
      '&fullname=' +
      formaValue.fullname +
      '&phone=' +
      formaValue.phone +
      '&company=' +
      formaValue.company +
      '&industry=' +
      formaValue.industry +
      '&role=' +
      formaValue.role +
      '&howLarge=' +
      formaValue.howLarge +
      '&howLong=' +
      formaValue.howLong +
      '&howMany=' +
      formaValue.howMany +
      '&growthGoal=' +
      formaValue.growthGoal +
      '&budget=' +
      formaValue.budget +
      '&lifecycle=' +
      formaValue.lifecycle +
      '&brand=' +
      formaValue.brand +
      '&leads=' +
      formaValue.leads +
      '&loyalty=' +
      formaValue.loyalty +
      '&sales=' +
      formaValue.sales +
      '&totalScore=' +
      this.totalScore +
      '&firstScore=' +
      this.firstScore +
      '&secondScore=' +
      this.secondScore +
      '&thirdScore=' +
      this.thirdScore +
      '&forthScore=' +
      this.forthScore +
      '&fifthScore=' +
      this.fifthScore +
      '&sixthScore=' +
      this.sixthScore +
      '&date=' +
      formaValue.date.toLocaleString('el-GR');
    console.log(url);

    this.http.get<any>(url).subscribe(
      (data) => console.log(data),
      (error) => console.error('There was an error!', error)
    );
  }
}
