<div class="periexomeno">
  <div
    class="banner"
    [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <div class="space"></div>
    <div class="itsTime">
      <h1 [innerHTML]="'home.itstime' | translate"></h1>
    </div>
    <div class="btnArea">
      <h2>{{ "home.review" | translate }}</h2>
      <a routerLink="/getstarted"
        ><button>{{ "home.startBTN" | translate }}</button></a
      >
    </div>
    <div class="space2"></div>
  </div>
  <div
    class="mobilebanner"
    [ngStyle]="{ 'background-image': 'url(' + mobbackgroundImage + ')' }"
  >
    <div class="space"></div>
    <div class="itsTime">
      <h1 [innerHTML]="'home.itstime' | translate"></h1>
    </div>
    <div class="btnArea">
      <h2>{{ "home.review" | translate }}</h2>
      <a routerLink="/getstarted"
        ><button>{{ "home.startBTN" | translate }}</button></a
      >
    </div>

    <div class="space2"></div>
  </div>
</div>
<div class="enzcontainerwhite">
  <div class="insideContainer">
    <app-intro></app-intro>
  </div>
</div>
<div class="full">
  <app-sixareas></app-sixareas>
</div>
<div class="enzcontainerwhite">
  <div class="insideContainer2">
    <app-rememberthink></app-rememberthink>
  </div>
</div>
