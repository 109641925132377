<div class="confirm-content">
  <div class="modal-header">
    <h4 class="modal-title">Create share link</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      Do you want to share these results? If that's the case we will create a
      unique url for you to share!
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close((sharepage = false))"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.close((sharepage = true))"
    >
      Yes Share
    </button>
  </div>
</div>
