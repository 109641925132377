<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="sectionTitle">
      <div class="titleTXT">{{ "registration.regTitle" | translate }}</div>
      <div class="titleIMG"></div>
    </div>

    <div class="twocols m-3">
      <div class="question">
        <!-- <div class="getResultsGraphic">
          <div class="resultsGraph"></div>
        </div>
        <div class="getResultsTxt">Get</div> -->
        <div
          class="slogan"
          [attr.your-custom]="'registration.GetSubtitle' | translate"
        >
          <div class="text">{{ "registration.Get" | translate }}</div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="qForm">
          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.Name" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="fullname"
              aria-describedby="textHelp"
              formControlName="fullname"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputtext1"
              >{{ "registration.Role" | translate }}*</label
            >
            <input
              type="text"
              class="form-control"
              id="role"
              aria-describedby="textHelp"
              formControlName="role"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.Email" | translate
            }}</label>
            <input
              [class.is-invalid]="qForm.get('email').invalid"
              type="email"
              class="form-control"
              id="email"
              aria-describedby="textHelp"
              formControlName="email"
            />
          </div>
          <small
            class="text-danger"
            *ngIf="qForm.get('email').hasError('pattern')"
          >
            Please use a business/work email address.
          </small>
          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.Phone" | translate
            }}</label>
            <input
              type="number"
              class="form-control"
              id="phone"
              aria-describedby="textHelp"
              formControlName="phone"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.Company" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="company"
              aria-describedby="textHelp"
              formControlName="company"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.Industry" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="industry"
              aria-describedby="textHelp"
              formControlName="industry"
            >
              <option *ngFor="let item of industries" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.HowLarge" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="howLarge"
              aria-describedby="textHelp"
              placeholder="howLarge"
              formControlName="howLarge"
            >
              <option *ngFor="let item of howlargesArr" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.HowLong" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="howLong"
              aria-describedby="textHelp"
              placeholder="howLong"
              formControlName="howLong"
            >
              <option *ngFor="let item of howlongArr" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.HowMany" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="howMany"
              aria-describedby="textHelp"
              placeholder="howMany"
              formControlName="howMany"
            >
              <option *ngFor="let item of hownanyArr" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.whichchoice" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="lifecycle"
              aria-describedby="textHelp"
              placeholder="lifecycle"
              formControlName="lifecycle"
            >
              <option *ngFor="let item of lifecycleStages" [value]="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.revenue" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="growthGoal"
              aria-describedby="textHelp"
              placeholder="growthGoal"
              formControlName="growthGoal"
            >
              <option *ngFor="let item of growthGoals" [value]="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.budget" | translate
            }}</label>
            <select
              type="text"
              class="form-control"
              id="budget"
              aria-describedby="textHelp"
              placeholder="budget"
              formControlName="budget"
            >
              <option
                *ngFor="let item of marketingBudgets"
                [value]="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="marketingObjs">
            <label for="exampleInputtext1">{{
              "registration.objectivesTxt" | translate
            }}</label>
            <div class="brand boldAndRadios">
              <b>{{ "registration.buildbrand" | translate }}</b>
              <div class="radios">
                <div class="radioItem">
                  <span>{{ "registration.high" | translate }}</span>
                  <input
                    name="brand"
                    type="radio"
                    id="brand"
                    value="High Priority"
                    formControlName="brand"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.medium" | translate }}</span>
                  <input
                    name="brand"
                    type="radio"
                    id="brand"
                    value="Medium Priority"
                    formControlName="brand"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.low" | translate }}</span>
                  <input
                    name="brand"
                    type="radio"
                    id="brand"
                    value="Low Priority"
                    formControlName="brand"
                  />
                </div>
              </div>
            </div>
            <div class="brand boldAndRadios">
              <b>{{ "registration.leads" | translate }}</b>
              <div class="radios">
                <div class="radioItem">
                  <span>{{ "registration.high" | translate }}</span>
                  <input
                    name="leads"
                    type="radio"
                    value="High Priority"
                    formControlName="leads"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.medium" | translate }}</span>
                  <input
                    name="leads"
                    type="radio"
                    value="Medium Priority"
                    formControlName="leads"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.low" | translate }}</span>
                  <input
                    name="leads"
                    type="radio"
                    value="Low Priority"
                    formControlName="leads"
                  />
                </div>
              </div>
            </div>
            <div class="brand boldAndRadios">
              <b>{{ "registration.loyalty" | translate }}</b>
              <div class="radios">
                <div class="radioItem">
                  <span>{{ "registration.high" | translate }}</span>
                  <input
                    name="loyalty"
                    type="radio"
                    value="High Priority"
                    formControlName="loyalty"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.medium" | translate }}</span>
                  <input
                    name="loyalty"
                    type="radio"
                    value="Medium Priority"
                    formControlName="loyalty"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.low" | translate }}</span>
                  <input
                    name="loyalty"
                    type="radio"
                    value="Low Priority"
                    formControlName="loyalty"
                  />
                </div>
              </div>
            </div>
            <div class="sales boldAndRadios">
              <b>{{ "registration.sales" | translate }}</b>
              <div class="radios">
                <div class="radioItem">
                  <span>{{ "registration.high" | translate }}</span>
                  <input
                    name="sales"
                    type="radio"
                    value="High Priority"
                    formControlName="sales"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.medium" | translate }}</span>
                  <input
                    name="sales"
                    type="radio"
                    value="Medium Priority"
                    formControlName="sales"
                  />
                </div>
                <div class="radioItem">
                  <span>{{ "registration.low" | translate }}</span>
                  <input
                    name="sales"
                    type="radio"
                    value="Low Priority"
                    formControlName="sales"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="exampleInputtext1">{{
              "registration.notes" | translate
            }}</label>

            <textarea
              class="form-control"
              id="sxolia"
              aria-describedby="textHelp"
              formControlName="sxolia"
              rows="3"
            ></textarea>
          </div>
          <div class="aggreement">
            <p>
              {{ "registration.terms1" | translate }}
            </p>
            <p>
              {{ "registration.terms2" | translate }}
            </p>
          </div>
          <div class="form-check inputlabel">
            <input type="checkbox" id="terms" formControlName="terms" />
            <label class="form-check-label" for="exampleCheck1"
              >{{ "registration.agree" | translate }}
              <a
                class="oroi"
                href="https://enzyme.gr/privacy-policy/"
                target="_blank"
                ><span> {{ "registration.policy" | translate }} </span></a
              ></label
            >
          </div>
          <!-- <div class="form-check inputlabel">
            <input type="checkbox" id="privacy" formControlName="terms2" />
            <label class="form-check-label" for="exampleCheck1"
              >I agree with the <span class="oroi">privacy policy</span></label
            >
          </div> -->
          <button
            [disabled]="qForm.pristine || qForm.invalid"
            (click)="submitForm()"
            type="submit"
            class="btn btn-primary mt-3"
          >
            {{ "registration.submit" | translate }}
          </button>
          <!-- <button
            [disabled]="qForm.pristine || qForm.invalid"
            (click)="submitForm()"
            type="submit"
            class="btn btn-primary mt-3"
          >
            Submit
          </button> -->
        </form>
      </div>
    </div>
  </div>
</div>
