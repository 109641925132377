<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="sectionTitle">
      <div class="titleTXT">{{ "fifth.title" | translate }}</div>
      <div class="titleIMG"></div>
    </div>

    <div class="twocols m-3" *ngIf="first">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "fifth.subtitle1" | translate }}</h2>

          <!-- <h5>CVP</h5> -->
          <div class="text">
            {{ questions[0] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <!-- <div class="square"></div> -->
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="firstForm" novalidate>
          <div class="inputlabel" *ngFor="let data of firstData; let i = index">
            <input
              name="firstRadios"
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, firstForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.firstForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 40;
                first = false;
                second = true;
                onSubmit(firstForm.value, 1)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="second">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "fifth.subtitle2" | translate }}</h2>
          <h5>{{ "fifth.subtitleBl2" | translate }}</h5>

          <div class="text">
            {{ questions[1] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <!-- <div class="square"></div> -->
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="secondForm" novalidate>
          <div
            class="inputlabel"
            *ngFor="let data of secondData; let i = index"
          >
            <input
              name="secondRadios"
              type="checkbox"
              [value]="data.value"
              (change)="onCheckboxChange($event, secondForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.secondForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 60;
                third = true;
                second = false;
                onSubmit(secondForm.value, 2)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="third">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "fifth.subtitle3" | translate }}</h2>

          <!-- <h5>Social Presence.</h5> -->
          <div class="text">
            {{ questions[2] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <!-- <div class="square"></div> -->
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="thirdForm" novalidate>
          <div class="inputlabel" *ngFor="let data of thirdData; let i = index">
            <input
              type="radio"
              name="thirdRadios"
              [value]="data.value"
              (change)="onRadioChange($event, thirdForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <div class="facebook" *ngIf="facebook">
            <div>
              <div class="question">
                FACEBOOK: Please select one: No likes yet, 1-50, 51-100,
                101-500, 500+ likes
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio1"
                  value="4"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >No likes yet</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="8"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">1-50</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="12"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >51-100</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="16"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >101-500</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >500+ Likes</label
                >
              </div>
            </div>
            <div>
              <div class="question">
                FACEBOOK: Please select one: Very little or no posts, 1-3 posts
                per month, 3+ posts per month
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio1"
                  value="5"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Very little or no posts</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio2"
                  value="15"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >1-3 posts per month</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >3+ posts per month</label
                >
              </div>
            </div>
          </div>
          <div class="linkdin" *ngIf="linkdin">
            <div>
              <div class="question">
                LINKEDIN: Please select one: No connections yet, 1-50, 51-100,
                101-500, 500+ connection
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio1"
                  value="4"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >No connections yet</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="8"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">1-50</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="12"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >51-100</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="16"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >101-500</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >500+ Connections</label
                >
              </div>
            </div>
            <div>
              <div class="question">
                LINKEDIN: Please select one: Very little or no posts, 1-3 posts
                per month, 3+ posts per month
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio1"
                  value="5"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Very little or no posts</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio2"
                  value="15"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >1-3 posts per month</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >3+ posts per month</label
                >
              </div>
            </div>
          </div>
          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 80;
                forth = true;
                third = false;
                onSubmit(thirdForm.value, 3)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="forth">
      <div class="empty" *ngIf="showNext"></div>
      <div class="question" *ngIf="!showNext">
        <div class="questionTXT">
          <h2>{{ "fifth.subtitle4" | translate }}</h2>
          <h5>{{ "fifth.subtitleBl4" | translate }}</h5>

          <div class="text">
            {{ questions[3] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <!-- <div class="square"></div> -->
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="forthForm" novalidate>
          <div class="if" *ngIf="!showNext">
            <div
              class="inputlabel"
              *ngFor="let data of forthData; let i = index"
            >
              <input
                type="checkbox"
                [value]="data.value"
                (change)="onCheckboxChange($event, forthForm, i)"
              />
              <label>
                {{ data.name }}
              </label>
            </div>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.forthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->
          <div class="showNext" *ngIf="showNext">
            <p [innerHTML]="'fifth.nextAreaTxt' | translate"></p>
            <div>
              <span [innerHTML]="'fifth.letsrate' | translate"></span>
              <span class="highlight">{{
                "fifth.nextAreaTitle" | translate
              }}</span>
            </div>
          </div>

          <div class="btnSubmit" *ngIf="!showNext">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                disabled = false;
                showNext = true;
                onSubmit(forthForm.value, 4)
              "
            />
          </div>
          <div class="btnNextSection" *ngIf="showNext">
            <input
              type="submit"
              value="{{ nextArea }}"
              (click)="goToNextArea()"
            />
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="twocols m-3" *ngIf="fifth">
      <div class="question">
        <div class="questionTXT">
          <h2>Strategy Strength</h2>
          <h5>Marketing Strategy Strength</h5>
          <div class="text">
            {{ questions[4] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="fifthForm" novalidate>
          <div class="if" *ngIf="!showNext">
            <div
              class="inputlabel"
              *ngFor="let data of fifthData; let i = index"
            >
              <input
                type="checkbox"
                [value]="data.value"
                (change)="onCheckboxChange($event, fifthForm, i)"
              />
              <label>
                {{ data.name }}
              </label>
            </div>
          </div>
  
          <div class="showNext" *ngIf="showNext">
            <p>
              Let's continue scoring. <br />
              Let's rate your
              <span class="highlight">Operational Area</span>.
            </p>
          </div>
          <div class="btnSubmit" *ngIf="!showNext">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                disabled = false;
                showNext = true;
                onSubmit(fifthForm.value, 5)
              "
            />
          </div>
          <div class="btnNextSection" *ngIf="showNext">
            <input type="submit" value="NEXT AREA" (click)="goToNextArea()" />
          </div>
        </form>
      </div>
    </div> -->
    <!-- <div class="totalScore">
      Total Score for first Section: {{ totalScore }}
    </div>
    <button
      [disabled]="disabled"
      type="button"
      class="btn btn-primary mt-3"
      routerLink="/second"
    >
      Next section
    </button> -->
  </div>
</div>
<div class="enzcontainer">
  <div class="progressbar">
    <ngb-progressbar
      type="primary"
      [value]="progress"
      [striped]="false"
    ></ngb-progressbar>
  </div>
</div>

<!-- <div class="enzcontainer">
  <div class="deleteLater">
    <h2>Ignore, we will delete later</h2>
    <div class="scores">
      <span class="score">{{ firstScore }}</span>
      <span class="score">{{ secondScore }}</span>
      <span class="score">{{ thirdScore }}</span>
      <span class="score">{{ forthScore }}</span>
      <span class="score">{{ fitfthScore }}</span>
    </div>
  </div>
</div> -->

<!-- <h2 class="mb-3">Third Section</h2>
<p>
  <ngb-progressbar
    type="primary"
    [value]="progress"
    [striped]="true"
  ></ngb-progressbar>
</p>
<div class="scores">
  <span class="score">{{ firstScore }}</span>
  <span class="score">{{ secondScore }}</span>
  <span class="score">{{ thirdScore }}</span>
  <span class="score">{{ forthScore }}</span>
</div>
<div class="card m-3" *ngIf="first">
  <h5 class="card-header">
    1. Marketing Analytics. Explore how your marketing analytics is organized.
    Please select the statement that best describes your company:
  </h5>
  <div class="card-body">
    <form [formGroup]="firstForm" novalidate>
      <div *ngFor="let data of firstData; let i = index">
        <label>
          <input
            type="radio"
            name="firstRadios"
            [value]="data.value"
            (change)="onRadioChange($event, firstForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.firstForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 25;
          first = false;
          second = true;
          onSubmit(firstForm.value, 1)
        "
      />
    </form>
  </div>
</div>
<div class="card m-3" *ngIf="second">
  <h5 class="card-header">
    2. Metrics & Benchmarking Definition. Explore your company’s marketing and
    customer experience metrics set-up and decision support. Please select all
    that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="secondForm" novalidate>
      <div *ngFor="let data of secondData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, secondForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.secondForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 50;
          third = true;
          second = false;
          onSubmit(secondForm.value, 2)
        "
      />
    </form>
  </div>
</div>
<div class="card m-3" *ngIf="third">
  <h5 class="card-header">
    3. Performance Analytics. Evaluate how well you have defined your marketing
    objectives and identified how success will be measured. Please select the
    statement that best describes your company:
  </h5>
  <div class="card-body">
    <form [formGroup]="thirdForm" novalidate>
      <div *ngFor="let data of thirdData; let i = index">
        <label>
          <input
            type="radio"
            name="thirdRadios"
            [value]="data.value"
            (change)="onRadioChange($event, thirdForm, i); showFBLinkedin(i)"
          />
          {{ data.name }}
        </label>
      </div>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 75;
          forth = true;
          third = false;
          onSubmit(thirdForm.value, 3)
        "
      />
    </form>
  </div>
</div>

<div class="card m-3" *ngIf="forth">
  <h5 class="card-header">
    4. Martech capabilities. Audit the availability and use of your marketing
    technology to refine its capabilities and prove its ROI. Please select all
    that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="forthForm" novalidate>
      <div *ngFor="let data of forthData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, forthForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.forthForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="progress = 100; disabled = false; onSubmit(forthForm.value, 4)"
      />
    </form>
  </div>
</div>

<div class="totalScore">Total Score for first Section: {{ totalScore }}</div>
<button
  [disabled]="disabled"
  type="button"
  class="btn btn-primary mt-3"
  routerLink="/forth"
>
  Next section
</button> -->

<!-- <h2 class="mb-3">Fifth Section</h2>
<p>
  <ngb-progressbar
    type="primary"
    [value]="progress"
    [striped]="true"
  ></ngb-progressbar>
</p>
<div class="scores">
  <span class="score">{{ firstScore }}</span>
  <span class="score">{{ secondScore }}</span>
  <span class="score">{{ thirdScore }}</span>
  <span class="score">{{ forthScore }}</span>
</div>
<div class="card m-3" *ngIf="first">
  <h5 class="card-header">
    1. Campaign Implementation Planning. Explore how your company's campaign
    plan is implemented and assess the phases that are regularly defined in your
    marketing campaign plan? Please select all that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="firstForm" novalidate>
      <div *ngFor="let data of firstData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, firstForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.firstForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 25;
          first = false;
          second = true;
          onSubmit(firstForm.value, 1)
        "
      />
    </form>
  </div>
</div>
<div class="card m-3" *ngIf="second">
  <h5 class="card-header">
    2. SWOT-Analysis. Assess the level of yours company's internal (strength &
    weakness) and external (threats-opportunities) environment . Please select
    the statement that best describes your company:
  </h5>
  <div class="card-body">
    <form [formGroup]="secondForm" novalidate>
      <div *ngFor="let data of secondData; let i = index">
        <label>
          <input
            type="radio"
            name="secondRadios"
            [value]="data.value"
            (change)="onRadioChange($event, secondForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.secondForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 50;
          third = true;
          second = false;
          onSubmit(secondForm.value, 2)
        "
      />
    </form>
  </div>
</div>
<div class="card m-3" *ngIf="third">
  <h5 class="card-header">
    3. Market Fit. Audit and evaluate the value and marketability of your
    products/services? Please select all that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="thirdForm" novalidate>
      <div *ngFor="let data of thirdData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, thirdForm, i); showFBLinkedin(i)"
          />
          {{ data.name }}
        </label>
      </div>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 75;
          forth = true;
          third = false;
          onSubmit(thirdForm.value, 3)
        "
      />
    </form>
  </div>
</div>

<div class="card m-3" *ngIf="forth">
  <h5 class="card-header">
    4. Budgeting & Investment. Analyze how your company budgets and proritize
    the investment. Which of these are regularly are important and used in your
    marketing budget? Please select all that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="forthForm" novalidate>
      <div *ngFor="let data of forthData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, forthForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.forthForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="progress = 100; disabled = false; onSubmit(forthForm.value, 4)"
      />
    </form>
  </div>
</div>

<div class="totalScore">Total Score for first Section: {{ totalScore }}</div>
<button
  [disabled]="disabled"
  type="button"
  class="btn btn-primary mt-3"
  routerLink="/sixth"
>
  Next section
</button> -->
