<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="sectionTitle">
      <div class="titleTXT">{{ "first.title" | translate }}</div>
      <div class="titleIMG"></div>
    </div>

    <div class="twocols m-3" *ngIf="first">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "first.subtitle1" | translate }}</h2>
          <h5>{{ "first.subtitleBl1" | translate }}</h5>
          <div class="text">
            {{ questions[0] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="firstForm" novalidate>
          <div class="inputlabel" *ngFor="let data of firstData; let i = index">
            <input
              type="checkbox"
              [value]="data.value"
              (change)="onCheckboxChange($event, firstForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.firstForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 40;
                first = false;
                second = true;
                onSubmit(firstForm.value, 1)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="second">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "first.subtitle2" | translate }}</h2>
          <h5>{{ "first.subtitleBl2" | translate }}</h5>
          <div class="text">
            {{ questions[1] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="secondForm" novalidate>
          <div
            class="inputlabel"
            *ngFor="let data of secondData; let i = index"
          >
            <input
              name="secondRadios"
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, secondForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.secondForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 60;
                third = true;
                second = false;
                onSubmit(secondForm.value, 2)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="third">
      <div class="question">
        <div class="questionTXT">
          <h2 [innerHTML]="'first.subtitle3' | translate"></h2>
          <h5>{{ "first.subtitleBl3" | translate }}</h5>
          <div class="text">
            {{ questions[2] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="thirdForm" novalidate>
          <div class="inputlabel">
            <input
              type="checkbox"
              [value]="10"
              (change)="
                onCheckboxChange($event, thirdForm, 0); showFBLinkedin(0)
              "
            />
            <label>{{ "first.hasFacebook" | translate }} </label>
          </div>

          <div class="facebook" *ngIf="facebook">
            <div>
              <div class="question">
                {{ "first.facebookpop" | translate }}
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio1"
                  value="4"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1">{{
                  "first.nolikes" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="8"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.50likes" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="12"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.100likes" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="16"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.500likes" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.morelikes" | translate
                }}</label>
              </div>
            </div>
            <div>
              <div class="question">
                {{ "first.fbfrequency" | translate }}
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio1"
                  value="5"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1">{{
                  "first.noposts" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio2"
                  value="15"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.3posts" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.moreposts" | translate
                }}</label>
              </div>
            </div>
          </div>

          <div class="divider"></div>
          <div class="inputlabel">
            <input
              type="checkbox"
              [value]="10"
              (change)="
                onCheckboxChange($event, thirdForm, 1); showFBLinkedin(1)
              "
            />
            <label> {{ "first.hasLinkedin" | translate }} </label>
          </div>
          <div class="linkdin" *ngIf="linkdin">
            <div>
              <div class="question">
                {{ "first.linkedinpop" | translate }}
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio1"
                  value="4"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1">{{
                  "first.noconnections" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="8"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.50connections" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="12"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.100connections" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="16"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.500connections" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.moreconnections" | translate
                }}</label>
              </div>
            </div>
            <div>
              <div class="question">
                {{ "first.linkedinfrequency" | translate }}
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio1"
                  value="5"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1">{{
                  "first.nolinkposts" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio2"
                  value="15"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.3linkposts" | translate
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">{{
                  "first.moreLinkposts" | translate
                }}</label>
              </div>
            </div>
          </div>
          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 80;
                forth = true;
                third = false;
                onSubmit(thirdForm.value, 3)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="forth">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "first.subtitle4" | translate }}</h2>
          <div class="text">
            {{ questions[3] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="forthForm" novalidate>
          <div class="inputlabel" *ngFor="let data of forthData; let i = index">
            <input
              type="checkbox"
              [value]="data.value"
              (change)="onCheckboxChange($event, forthForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.forthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                fifth = true;
                forth = false;
                onSubmit(forthForm.value, 4)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="fifth">
      <div class="empty" *ngIf="showNext"></div>
      <div class="question" *ngIf="!showNext">
        <div class="questionTXT">
          <h2>{{ "first.subtitle5" | translate }}</h2>
          <h5>{{ "first.subtitleBl5" | translate }}</h5>
          <div class="text">
            {{ questions[4] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="fifthForm" novalidate>
          <div class="if" *ngIf="!showNext">
            <div
              class="inputlabel"
              *ngFor="let data of fifthData; let i = index"
            >
              <input
                type="checkbox"
                [value]="data.value"
                (change)="onCheckboxChange($event, fifthForm, i)"
              />
              <label>
                {{ data.name }}
              </label>
            </div>
          </div>
          <!-- 
          <p
            class="error"
            *ngIf="this.fifthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->
          <div class="showNext" *ngIf="showNext">
            <p [innerHTML]="'first.nextAreaTxt' | translate"></p>
            <div>
              <span [innerHTML]="'first.letsrate' | translate"></span>
              <span class="highlight">{{
                "first.nextAreaTitle" | translate
              }}</span>
            </div>
          </div>
          <div class="btnSubmit" *ngIf="!showNext">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                disabled = false;
                showNext = true;
                onSubmit(fifthForm.value, 5)
              "
            />
          </div>
          <div class="btnNextSection" *ngIf="showNext">
            <input
              type="submit"
              value="{{ nextArea }}"
              (click)="goToNextArea()"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="enzcontainer">
  <div class="progressbar">
    <ngb-progressbar
      type="primary"
      [value]="progress"
      [striped]="false"
    ></ngb-progressbar>
  </div>
</div>

<!-- <div class="enzcontainer">
  <div class="deleteLater">
    <h2>Ignore, we will delete later</h2>
    <div class="scores">
      <span class="score">{{ firstScore }}</span>
      <span class="score">{{ secondScore }}</span>
      <span class="score">{{ thirdScore }}</span>
      <span class="score">{{ forthScore }}</span>
      <span class="score">{{ fitfthScore }}</span>
    </div>
  </div>
</div> -->
