import { Component, OnInit } from '@angular/core';
import { LocalService } from './../../services/local.service';
import { LanguageService } from './../../services/language.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  questions: any;
  score: number;
  responses: any;
  firstForm: any;
  backgroundImage: any;
  mobbackgroundImage: any;
  subscription: Subscription;
  currentLanguage: string;
  constructor(
    private localserv: LocalService,
    private languageService: LanguageService
  ) {
    this.responses = {
      qnum: 1,
    };

    this.setBackgroundImage();
  }

  setBackgroundImage() {
    const language = this.currentLanguage;
    switch (language) {
      case 'en':
        this.backgroundImage =
          '../../../assets/home/desktop/home_header_desktop.jpg';
        this.mobbackgroundImage =
          '../../../assets/home/mobile/home_header_mobile.jpg';
        break;
      case 'el':
        this.backgroundImage =
          '../../../assets/home/desktop/home_header_desktop.jpg';
        this.mobbackgroundImage =
          '../../../assets/home/mobile/home_header_mobile.jpg';
        break;
      case 'fr':
        this.backgroundImage =
          '../../../assets/home/desktop/home_header_desktop.jpg';
        this.mobbackgroundImage =
          '../../../assets/home/mobile/home_header_mobile.jpg';
        break;
      default:
        this.backgroundImage =
          '../../../assets/home/desktop/home_header_desktop.jpg'; // default to English
        this.mobbackgroundImage =
          '../../../assets/home/desktop/home_header_mobile.jpg'; // default to English
    }
  }

  ngOnInit(): void {
    // Load the language from local storage when the app initializes
    this.languageService.loadLanguageFromLocalStorage();

    this.subscription = this.languageService.currentLanguage$.subscribe(
      (language) => {
        this.currentLanguage = language;
        this.setBackgroundImage();
        console.log(this.currentLanguage);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSubmit(form) {
    console.log(form.value);
  }
}
