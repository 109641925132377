<div class="periexomeno">
  <div class="banner">
    <div class="stararea"></div>
    <div class="areas">
      <h1 [attr.your-custom]="'learnmore.subtitle' | translate">
        {{ "learnmore.title" | translate }}
      </h1>
    </div>
    <div class="slogan">
      <h5>{{ "learnmore.slogan" | translate }}</h5>
    </div>
  </div>
</div>
<div class="fasaorange">
  <div class="fasacontent">
    <div class="content">
      {{ "learnmore.orangeTxt" | translate }}
    </div>
  </div>
</div>
<div class="enzcontainerwhite">
  <div class="insideContainer">
    <app-sixsections></app-sixsections>
  </div>
</div>
<app-understandscore></app-understandscore>
<div class="enzcontainerwhite pattern">
  <div class="insideContainer">
    <app-foursteps></app-foursteps>
  </div>
</div>
<div class="fasaorangeright">
  <div class="fasacontentright">
    <div class="contentright">
      <h2 [innerHTML]="'learnmore.enzReportTitle' | translate"></h2>
      <div class="cta">
        <p>
          {{ "learnmore.enzReportTxt" | translate }}
        </p>
        <a routerLink="/getstarted"
          ><button>{{ "learnmore.getstartedBTN" | translate }}</button></a
        >
      </div>
      <div class="smallpattern"></div>
    </div>
  </div>
</div>
