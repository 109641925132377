import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexStroke,
  ApexFill,
  ChartComponent,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexXAxis,
} from 'ng-apexcharts';
import { LocalService } from 'src/app/services/local.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/modals/confirm/confirm.component';
import { FiredataService } from 'src/app/services/firedata.service';
import { SharedurlComponent } from 'src/app/modals/sharedurl/sharedurl.component';
import { ActivatedRoute } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  fill: ApexFill;
};

export type ChartOptionsRadar = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  xaxis: ApexXAxis;
};
@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss'],
})
export class SharedComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chartRadar') chartRadar: ChartComponent;
  private itemDoc: AngularFirestoreDocument<any>;
  doc: Observable<any>;

  public chartOptions: any;
  public ChartOptionsRadar: any;
  confirmmodalRef: any;
  linkmodalRef: any;
  converted = '';
  firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fifthScore = 0;
  sixthScore = 0;
  totalScore = 0;
  stringScore = '';
  scoreMessage = '';
  scoreTitle = '';
  recommendation1 = '';
  recommendation2 = '';
  recommendation3 = '';
  recommendation4 = '';
  recommendation5 = '';
  recommendation6 = '';
  f1 = [];
  f2 = [];
  f3 = [];
  f4 = [];
  f5 = [];
  s1 = [];
  s2 = [];
  s3 = [];
  s4 = [];
  s5 = [];
  t1 = [];
  t2 = [];
  t3 = [];
  t4 = [];
  fo1 = [];
  fo2 = [];
  fo3 = [];
  fo4 = [];
  fo5 = [];
  fi1 = [];
  fi2 = [];
  fi3 = [];
  fi4 = [];
  si1 = [];
  si2 = [];
  si3 = [];
  si4 = [];
  constructor(
    private localData: LocalService,
    public modalService: NgbModal,
    private fireServ: FiredataService,
    private route: ActivatedRoute,
    private afs: AngularFirestore
  ) {
    console.log('go');

    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      console.log(id);
      this.itemDoc = afs.doc<any>('links/' + id);
      this.doc = this.itemDoc.valueChanges();
      // gjdhdaebcd
      this.doc.subscribe((x) => {
        console.log(x);
      });
    });
    this.firstScore = this.localData.firstScore;
    this.secondScore = this.localData.secondScore;
    this.thirdScore = this.localData.thirdScore;
    this.forthScore = this.localData.forthScore;
    this.fifthScore = this.localData.fifthScore;
    this.sixthScore = this.localData.sixthScore;
    this.totalScore =
      (this.firstScore +
        this.secondScore +
        this.thirdScore +
        this.forthScore +
        this.fifthScore +
        this.sixthScore) /
      6;

    // mockdata
    // this.firstScore = 95;
    // this.secondScore = 95;
    // this.thirdScore = 93.75;
    // this.forthScore = 80;
    // this.fifthScore = 100;
    // this.sixthScore = 87.5;
    // this.totalScore = 97.88888;

    if (this.totalScore >= 76 && this.totalScore <= 100) {
      this.stringScore = '4: Sophisticated';
      this.scoreTitle = 'Marketing Mastery';
      this.scoreMessage =
        'your company is implementing insightful digital marketing campaigns that support lead generation, enhance customer loyalty, create value, and grow your business.';
    }
    if (this.totalScore >= 51 && this.totalScore <= 75) {
      this.stringScore = '3: Mature';
      this.scoreTitle = 'Digital gravity';
      this.scoreMessage =
        'your brand has moved into a beyond average level to create integrated and digital marketing plans that work well over the long haul. A little more persistence is needed. ';
    }
    if (this.totalScore >= 26 && this.totalScore <= 50) {
      this.stringScore = '2: Young';
      this.scoreTitle = 'Marketing enthusiasm';
      this.scoreMessage =
        'your company implements inconclusive marketing projects, based on occasional feedback you receive for your products and services, without long-term, strategic planning and campaigning.';
    }
    if (this.totalScore >= 0 && this.totalScore <= 25) {
      this.stringScore = '1: First Steps';
      this.scoreTitle = 'Digital wandering ';
      this.scoreMessage =
        'your company has just taken its initial, hesitative steps into the world of B2B marketing. You ‘ll be needing more confidence and belief in the value brought by your digital presence. ';
    }
    // / delete later mockdata

    this.polarChart();
    // this.radarChart();
    this.recommendAll();
    this.getAnswers();
  }

  getAnswers() {
    this.f1 = this.localData.f1;
    this.f2 = this.localData.f2;
    this.f3 = this.localData.f3;
    this.f4 = this.localData.f4;
    this.f5 = this.localData.f5;

    this.s1 = this.localData.s1;
    this.s2 = this.localData.s2;
    this.s3 = this.localData.s3;
    this.s4 = this.localData.s4;
    this.s5 = this.localData.s5;

    this.t1 = this.localData.t1;
    this.t2 = this.localData.t2;
    this.t3 = this.localData.t3;
    this.t4 = this.localData.t4;

    this.fo1 = this.localData.fo1;
    this.fo2 = this.localData.fo2;
    this.fo3 = this.localData.fo3;
    this.fo4 = this.localData.fo4;
    this.fo5 = this.localData.fo5;

    this.fi1 = this.localData.fi1;
    this.fi2 = this.localData.fi2;
    this.fi3 = this.localData.fi3;
    this.fi4 = this.localData.fi4;

    this.si1 = this.localData.si1;
    this.si2 = this.localData.si2;
    this.si3 = this.localData.si3;
    this.si4 = this.localData.si4;
  }

  recommendAll() {
    this.recommend(this.firstScore, 1);
    this.recommend(this.secondScore, 2);
    this.recommend(this.thirdScore, 3);
    this.recommend(this.forthScore, 4);
    this.recommend(this.fifthScore, 5);
    this.recommend(this.sixthScore, 6);
  }

  recommend(score, i) {
    var rec = '';
    switch (i) {
      case 1:
        var recs = this.localData.rec1;
        break;
      case 2:
        var recs = this.localData.rec2;
        break;
      case 3:
        var recs = this.localData.rec3;
        break;
      case 4:
        var recs = this.localData.rec4;
        break;
      case 5:
        var recs = this.localData.rec5;
        break;
      case 6:
        var recs = this.localData.rec6;
        break;

      default:
        break;
    }
    if (score < 26) {
      rec = recs[0];
    }
    if (score < 51 && score > 25) {
      rec = recs[1];
    }
    if (score < 76 && score > 50) {
      rec = recs[2];
    }
    if (score < 101 && score > 75) {
      rec = recs[3];
    }
    switch (i) {
      case 1:
        this.recommendation1 = rec;
        break;
      case 2:
        this.recommendation2 = rec;
        break;
      case 3:
        this.recommendation3 = rec;
        break;
      case 4:
        this.recommendation4 = rec;
        break;
      case 5:
        this.recommendation5 = rec;
        break;
      case 6:
        this.recommendation6 = rec;
        break;

      default:
        break;
    }
  }
  // / recommend

  radarChart() {
    this.ChartOptionsRadar = {
      series: [
        {
          name: 'Your Data',
          data: [
            this.firstScore,
            this.secondScore,
            this.thirdScore,
            this.forthScore,
            this.fifthScore,
            this.sixthScore,
          ],
        },
        {
          name: 'Average Data',

          data: [90, 95, 80, 94, 87, 86],
        },
      ],
      chart: {
        height: 600,
        type: 'radar',
      },
      title: {
        text: 'Assesment',
      },
      xaxis: {
        categories: [
          'First Section',
          'Second Section',
          'Third Section',
          'Forth Section',
          'Fifth Section',
          'Sixth Section',
        ],
      },
    };
    // / radar
  }

  polarChart() {
    this.chartOptions = {
      series: [
        this.firstScore,
        this.secondScore,
        this.thirdScore,
        this.forthScore,
        this.fifthScore,
        this.sixthScore,
      ],
      chart: {
        type: 'polarArea',
        height: 600,
        legend: {
          show: false,
        },
      },
      stroke: {
        colors: ['#fff'],
      },
      colors: [
        '#FF7200',
        '#FFA800',
        '#FF5400',
        '#9E9E9E',
        '#545454',
        '#0A0500',
      ],
      fill: {
        opacity: 1,
      },
      labels: [
        'Essential Marketing Infrastructure',
        'Organizational Area',
        'Metrics-Analytical Area',
        'Customer Relationship Management',
        'Operational',
        'Strategy Planning',
      ],
      legend: {
        show: false,
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    // / polar
  }

  setCharts() {
    console.log('charts');
    this.polarChart();
    this.radarChart();
    this.recommendAll();
  }
  ngOnInit(): void {}

  printPage() {
    window.print();
  }
  sharePage() {
    console.log('ask for share');
    this.confirmmodalRef = this.modalService.open(ConfirmComponent);
    this.confirmmodalRef.componentInstance.name = 'World';
    this.confirmmodalRef.result.then(
      (result) => {
        if (result) {
          console.log('share');
          const id = this.convertMobile('6937304123');

          const shareData = {
            firstScore: this.firstScore,
            secondScore: this.secondScore,
            thirdScore: this.thirdScore,
            forthScore: this.forthScore,
            fifthScore: this.fifthScore,
            sixthScore: this.sixthScore,
            totalScore: this.totalScore,
            id: id,
          };
          this.fireServ.createLink(shareData, id);
          this.linkmodalRef = this.modalService.open(SharedurlComponent);
          this.linkmodalRef.componentInstance.url = 'World';
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  convertMobile(mobile: string) {
    // 👇️ for Uppercase letters, replace `a` with `A`
    this.converted = '';
    for (let i = 0; i < mobile.length; i++) {
      const element = mobile[i];
      const code = 'a'.charCodeAt(0);
      console.log(code); // 👉️ 97
      this.converted += String.fromCharCode(code + parseInt(element));
    }

    return this.converted;
  }
}
