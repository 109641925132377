import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private currentLanguageSubject = new BehaviorSubject<string>('en');
  public currentLanguage$ = this.currentLanguageSubject.asObservable();

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  // Method to get the current language
  getCurrentLanguage(): string {
    return this.currentLanguageSubject.value;
  }

  // Method to change the language
  changeLanguage(language: string): void {
    this.currentLanguageSubject.next(language);
    this.translateService.use(language);
    this.storeLanguageInLocalStorage();
  }

  // Store the selected language in local storage
  private storeLanguageInLocalStorage(): void {
    localStorage.setItem('selectedLanguage', this.currentLanguageSubject.value);
  }

  // Load the language from local storage
  loadLanguageFromLocalStorage(): void {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.currentLanguageSubject.next(storedLanguage);
      this.translateService.use(storedLanguage);
    }
  }
}
