<div class="twocols mobile">
  <div class="left">
    <div class="item">
      <div class="num">1</div>
      <div class="stage">
        <div class="score">0 - 25 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageOneTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageOneTxt" | translate }}
        </p>
      </div>
    </div>
    <div class="item">
      <div class="num">2</div>

      <div class="stage">
        <div class="score">26 - 50 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageTwoTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageTowTxt" | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="item">
      <div class="num">3</div>
      <div class="stage">
        <div class="score">51 - 75 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageThreeTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageThreeTxt" | translate }}
        </p>
      </div>
    </div>
    <div class="item">
      <div class="num">4</div>
      <div class="stage">
        <div class="score">76 - 100 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageFourTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageFourTxt" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="twocols desktop">
  <div class="left">
    <div class="item">
      <div class="num">1</div>
      <div class="stage">
        <div class="score">0 - 25 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageOneTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageOneTxt" | translate }}
        </p>
      </div>
    </div>
    <div class="item">
      <div class="num">3</div>
      <div class="stage">
        <div class="score">51 - 75 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageThreeTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageThreeTxt" | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="item">
      <div class="num">2</div>
      <div class="stage">
        <div class="score">26 - 50 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageTwoTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageTowTxt" | translate }}
        </p>
      </div>
    </div>
    <div class="item">
      <div class="num">4</div>
      <div class="stage">
        <div class="score">76 - 100 {{ "learnmore.points" | translate }}</div>
        <h4>{{ "learnmore.stageFourTitle" | translate }}</h4>
        <p>
          {{ "learnmore.stageFourTxt" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="down">
  <p [innerHTML]="'learnmore.apartFrom' | translate"></p>
</div>
