import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { LocalService } from 'src/app/services/local.service';
import { LocalServiceGR } from 'src/app/services/localgr.service';
import { LocalServiceFR } from 'src/app/services/localfr.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-third',
  templateUrl: './third.component.html',
  styleUrls: ['./third.component.scss'],
})
export class ThirdComponent implements OnInit {
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  forthForm: FormGroup;
  fifthForm: FormGroup;
  facebook = false;
  linkdin = false;
  date: any;
  score = 0;
  firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fitfthScore = 0;
  totalScore = 0;
  first = true;
  second = false;
  third = false;
  forth = false;
  fifth = false;
  progress = 0;
  disabled = true;
  firstData: Array<any> = [];

  secondData: Array<any> = [];

  thirdData: Array<any> = [];

  forthData: Array<any> = [];
  fifthData: Array<any> = [];
  // fbq1 = 0;
  // fbq2 = 0;
  // linkq1 = 0;
  // linkq2 = 0;
  questions: any = [];
  localData: any;
  currentLanguage: string;
  subscription: Subscription;
  range1a = 0;
  range1b = 0;
  range1c = 0;
  range1d = 0;
  range1e = 0;
  range2a = 0;
  range2b = 0;
  range2c = 0;
  range2d = 0;
  range2e = 0;
  range5a = 0;
  range5b = 0;
  range5c = 0;
  range5d = 0;
  range5e = 0;
  nextTxt = 'NEXT';
  nextArea = 'NEXT AREA';
  constructor(
    private fb: FormBuilder,
    private localDataen: LocalService,
    private localDatagr: LocalServiceGR,
    private localDatafr: LocalServiceFR,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.date = new Date();

    this.localData = this.localDataen;
    this.setQuestions();
  }
  // / constr
  setQuestions() {
    if (this.currentLanguage === 'en') {
      this.localData = this.localDataen;
      this.nextTxt = 'NEXT';
      this.nextArea = 'NEXT AREA';
    }
    if (this.currentLanguage === 'el') {
      this.localData = this.localDatagr;
      this.nextTxt = 'ΕΠΟΜΕΝΗ';
      this.nextArea = 'ΕΠΟΜΕΝΟΣ ΤΟΜΕΑΣ';
    }
    if (this.currentLanguage === 'fr') {
      this.localData = this.localDatafr;
      this.nextTxt = 'SUIVANTE';
      this.nextArea = 'ZONE SUIVANTE';
    }
    this.firstData = this.localData.thirdSection[0].questions;
    this.secondData = this.localData.thirdSection[1].questions;
    this.thirdData = this.localData.thirdSection[2].questions;
    this.forthData = this.localData.thirdSection[3].questions;
    this.fifthData = this.localData.thirdSection[4].questions;
    this.questions = [];

    this.questions.push(this.localData.thirdSection[0].qtxt);
    this.questions.push(this.localData.thirdSection[1].qtxt);
    this.questions.push(this.localData.thirdSection[2].qtxt);
    this.questions.push(this.localData.thirdSection[3].qtxt);
    this.questions.push(this.localData.thirdSection[4].qtxt);
    //console.log(this.questions);

    this.firstForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
    this.secondForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
    this.thirdForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });

    this.forthForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
    this.fifthForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.subscription = this.languageService.currentLanguage$.subscribe(
      (language) => {
        this.currentLanguage = language;
        this.setQuestions();
        console.log(this.currentLanguage);
      }
    );
  }
  // / init

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  showFBLinkedin(i) {
    if (i === 0) {
      this.facebook = !this.facebook;
    }
    if (i === 1) {
      this.linkdin = !this.linkdin;
    }
  }
  // / showFBLinkedin

  onCheckboxChange(e, form, num) {
    const checkArray: FormArray = form.get('checkArray') as FormArray;
    const answersArr: FormArray = form.get('answersArr') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      answersArr.push(new FormControl(num));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
      answersArr.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          answersArr.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  // /onCheckboxChange

  onRadioChange(e, form, num) {
    const checkArray: FormArray = form.get('checkArray') as FormArray;
    const answersArr: FormArray = form.get('answersArr') as FormArray;

    if (e.target.checked) {
      checkArray.removeAt(0);
      checkArray.push(new FormControl(e.target.value));
      answersArr.removeAt(0);
      answersArr.push(new FormControl(num));
    }
  }
  // /onRadioChange

  onSubmit(form, i) {
    console.log(this.firstForm.value);

    this.totalScore = 0;
    const formaValue = form;

    // formaValue.date = this.date;
    this.score = 0;
    formaValue.checkArray.forEach((element) => {
      this.score = this.score + Number(element);
    });
    if (i === 1) {
      this.score =
        this.score +
        (this.range1a +
          this.range1b +
          this.range1c +
          this.range1d +
          this.range1e) *
          5;

      formaValue.answersArr.push(this.range1a - 1);
      formaValue.answersArr.push(this.range1b - 1);
      formaValue.answersArr.push(this.range1c - 1);
      formaValue.answersArr.push(this.range1d - 1);
    }
    if (i === 2) {
      this.score =
        this.score +
        (this.range2a +
          this.range2b +
          this.range2c +
          this.range2d +
          this.range2e) *
          5;
      formaValue.answersArr.push(this.range2a - 1);
      formaValue.answersArr.push(this.range2b - 1);
      formaValue.answersArr.push(this.range2c - 1);
      formaValue.answersArr.push(this.range2d - 1);
    }
    if (i === 5) {
      this.score =
        this.score +
        (this.range5a +
          this.range5b +
          this.range5c +
          this.range5d +
          this.range5e) *
          4;
      formaValue.answersArr.push(this.range5a - 1);
      formaValue.answersArr.push(this.range5b - 1);
      formaValue.answersArr.push(this.range5c - 1);
      formaValue.answersArr.push(this.range5d - 1);
      formaValue.answersArr.push(this.range5e - 1);
    }

    console.log(formaValue.answersArr);
    switch (i) {
      case 1:
        this.firstScore = this.score;
        this.localData.t1 = formaValue.answersArr;
        break;
      case 2:
        this.secondScore = this.score;
        this.localData.t2 = formaValue.answersArr;
        break;
      case 3:
        this.thirdScore = this.score;
        this.localData.t3 = formaValue.answersArr;
        break;
      case 4:
        this.forthScore = this.score;
        this.localData.t4 = formaValue.answersArr;
        break;
      case 5:
        this.fitfthScore = this.score;
        this.localData.t5 = formaValue.answersArr;
        break;

      default:
        break;
    }

    this.totalScore =
      (this.firstScore +
        this.secondScore +
        this.thirdScore +
        this.forthScore +
        this.fitfthScore) /
      5;

    this.localData.setThirdScore(this.totalScore);
    console.log(formaValue);
    // this.loadSpinner(formaValue);
  }
  // / submit
  goToNextArea() {
    console.log('go to next area');
    this.router.navigate(['/forth']);
  }
}
// / class
