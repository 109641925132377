import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  languages = [
    { code: 'en', flag: 'assets/uk.png' },
    { code: 'fr', flag: 'assets/france.png' },
    { code: 'el', flag: 'assets/greece.png' },
  ];

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.loadLanguageFromLocalStorage();
  }
  changeLanguage(language: string): void {
    this.languageService.changeLanguage(language);
  }
  isSelected(languageCode: string): boolean {
    return this.languageService.getCurrentLanguage() === languageCode;
  }
}
