<div class="periexomeno">
  <div class="banner">
    <div class="imageBG"></div>
    <div class="theSix">
      <h2 [innerHTML]="'home.sixTitle' | translate"></h2>
      <p [innerHTML]="'home.sixSub' | translate"></p>
    </div>
    <div class="sixicons">
      <div class="three">
        <div class="item">
          <img src="./assets/home/icons/marketing.png" alt="" />
          <div>{{ "home.sixOne" | translate }}</div>
        </div>
        <div class="item">
          <img src="./assets/home/icons/organizational.png" alt="" />
          <div>{{ "home.sixTwo" | translate }}</div>
        </div>
        <div class="item">
          <img src="./assets/home/icons/metrics.png" alt="" />
          <div>{{ "home.sixThree" | translate }}</div>
        </div>
      </div>
      <div class="three">
        <div class="item">
          <img src="./assets/home/icons/customerrel.png" alt="" />
          <div>{{ "home.sixFour" | translate }}</div>
        </div>
        <div class="item">
          <img src="./assets/home/icons/operational.png" alt="" />
          <div>{{ "home.sixFive" | translate }}</div>
        </div>
        <div class="item">
          <img src="./assets/home/icons/marketing.png" alt="" />
          <div>{{ "home.sixSix" | translate }}</div>
        </div>
      </div>
    </div>

    <div class="orangebg"></div>
  </div>
</div>
