<p [innerHTML]="'home.guideTXT' | translate"></p>
<h2>{{ "home.rememberTitle" | translate }}</h2>

<p [innerHTML]="'home.remTXT' | translate"></p>
<h2 class="orange" [innerHTML]="'home.process' | translate"></h2>
<div class="buttonsArea">
  <div class="item">
    <a routerLink="/learnmore"
      ><button class="gray">{{ "home.learnmoreBTN" | translate }}</button></a
    >
  </div>
  <div class="item">
    <a routerLink="/getstarted"
      ><button class="black">{{ "home.getstartedBTN" | translate }}</button></a
    >
  </div>
</div>
