<div class="periexomeno">
  <div class="slogan" [attr.your-custom]="'getstarted.subtitle' | translate">
    <div class="text">
      {{ "getstarted.LetsGet" | translate }}
    </div>
    <div class="playbtn">
      <img
        class="getstarted"
        routerLink="/first"
        src="../../../assets//getstarted/start.png"
        alt=""
      />
    </div>
  </div>
</div>
