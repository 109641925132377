import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rememberthink',
  templateUrl: './rememberthink.component.html',
  styleUrls: ['./rememberthink.component.scss']
})
export class RememberthinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
