import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-sharedurl',
  templateUrl: './sharedurl.component.html',
  styleUrls: ['./sharedurl.component.scss'],
})
export class SharedurlComponent implements OnInit {
  @Input() url;
  sharepage = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  copyToClipboard(el: HTMLDivElement) {
    console.log(el.innerText);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(el.innerText).then(
        () => {
          // alert('Copied to Clipboard');
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Browser do not support Clipboard API');
    }
  }
}
