<div class="confirm-content">
  <div class="modal-header">
    <h4 class="modal-title">Get your link</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <p>Copy this url to share your results!</p>
    <div class="content">
      <span class="url" #elementContentToCopy>{{ url }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="
        activeModal.close((sharepage = false));
        copyToClipboard(elementContentToCopy)
      "
    >
      Copy
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.close((sharepage = true))"
    >
      Close
    </button>
  </div>
</div>
