import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalServiceFR {
  firstSection: any;
  secondSection: any;
  thirdSection: any;
  forthSection: any;
  fifthSection: any;
  sixthSection: any;
  company = '';
  public firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fifthScore = 0;
  sixthScore = 0;

  rec1: any;
  rec2: any;
  rec3: any;
  rec4: any;
  rec5: any;
  rec6: any;
  fbAnswers = [];
  linkdinAnswrs = [];
  f1 = [];
  f2 = [];
  f3 = [];
  f4 = [];
  f5 = [];
  s1 = [];
  s2 = [];
  s3 = [];
  s4 = [];
  s5 = [];
  t1 = [];
  t2 = [];
  t3 = [];
  t4 = [];
  t5 = [];
  fo1 = [];
  fo2 = [];
  fo3 = [];
  fo4 = [];
  fo5 = [];
  fi1 = [];
  fi2 = [];
  fi3 = [];
  fi4 = [];
  si1 = [];
  si2 = [];
  si3 = [];
  si4 = [];
  constructor() {
    this.firstSection = [
      {
        qtxt: "Veuillez fournir les informations du site Web de votre entreprise. Dans quelle mesure les produits/solutions/services et les offres de contenu pertinents sont-ils organisés sur votre site Web ? Votre site Web soutient-il désormais votre équipe commerciale avec des informations complètes et un contenu interactif et intéressant ? Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '1a',
        questions: [
          { name: 'Mon entreprise possède un site Web.', value: 20 },
          {
            name: 'Le site Web de mon entreprise représente une culture de messages de qualité, de profondeur de contenu, de graphiques riches, de simplicité de navigation, de présentation, etc. Il existe des liens de navigation de base pour les offres de produits/solutions/services.',
            value: 20,
          },
          {
            name: "Il existe une variété d'offres de contenu technique et commercial (comme des ebooks, des webinaires, des articles, des infographies, des études de cas, des livres blancs, des vidéos explicatives, etc.) sur le site Web de mon entreprise.",
            value: 20,
          },
          {
            name: "Le site Web de notre entreprise contient une gamme dynamique de ressources, de conseils et d'orientations liés à nos services, ainsi que des informations sur nos principaux partenariats avec des fournisseurs. Il propose également un formulaire d'abonnement par courrier électronique à nos newsletters.",
            value: 20,
          },
          {
            name: "Le site Web de mon entreprise dispose d'un blog et est régulièrement mis à jour avec du nouveau contenu et des informations liées à nos offres. Presque chaque page contient un appel à l’action. ",
            value: 20,
          },
          { name: 'Aucune des réponses ci-dessus.', value: 0 },
        ],
      },
      {
        qtxt: "Quelle est l’importance de la présence hors ligne de votre entreprise ? Votre entreprise utilise-t-elle des événements et des conférences pour l'engagement, le réseautage et l'extraversion ? Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:",
        q: '1b',
        questions: [
          {
            name: "L'organisation d'événements, la participation à des salons professionnels et à des conférences tierces sont incluses dans la planification annuelle.",
            value: 100,
          },
          {
            name: "Mon entreprise maintient une présence adéquate et une politique extravertie qui peut être considérée comme un bon choix pour l'industrie (organiser au moins 3 événements par an et participer à 2 salons professionnels ou conférences tierces.)",
            value: 75,
          },
          {
            name: 'Mon entreprise organise occasionnellement des événements et des rendez-vous individuels avec des clients et prospects et est très présente dans des conférences tierces.',
            value: 50,
          },
          {
            name: "Mon entreprise organise rarement des événements et est légèrement présente dans certaines conférences, mais n'a pas de stratégie ou d'objectifs spécifiques sur la manière dont nous devrions l'utiliser.",
            value: 25,
          },
          { name: 'Aucune des réponses ci-dessus', value: 0 },
        ],
      },
      {
        qtxt: 'Veuillez fournir des informations sur les pages de réseaux sociaux de votre entreprise, puis préciser le niveau de son activité sur les réseaux sociaux en citant la popularité et la fréquence de publication sur vos comptes LinkedIn et Facebook.',
        q: '1c',
        questions: [
          { name: 'Mon entreprise a une page Facebook', value: 10 },
          { name: 'Mon entreprise a une page LinkedIn.', value: 10 },
        ],
      },
      {
        qtxt: "Veuillez fournir les processus ou fonctionnalités du site Web qui sont en place ou actifs pour générer des prospects et du trafic. Parmi les éléments suivants, lesquels sont présents sur le site Web de votre entreprise ? Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '1d',
        questions: [
          {
            name: 'Un numéro de téléphone ou des options de chat en direct (Messenger/ Skype / Whatsapp/ Viber ou autre) sont à la disposition des clients pour faciliter les contacts et demandes de renseignements.',
            value: 10,
          },
          {
            name: "L'entreprise possède un compte Google My Business.",
            value: 10,
          },
          {
            name: "Le site Web de l'entreprise dispose d'un formulaire de contact en ligne.",
            value: 10,
          },
          {
            name: "Des réponses aux pistes issues d'événements, de recherches, de références, de contenu sécurisé et d'autres engagements pertinents ont été mis en place. ",
            value: 10,
          },
          {
            name: "Le site Web de l'entreprise est optimisé pour les mobiles.",
            value: 10,
          },
          {
            name: 'Il existe une plateforme Email/Newsletter pour générer du contenu et informer sur les réponses des clients aux offres.',
            value: 10,
          },
          {
            name: 'Le contenu du site Web tel que les articles, les textes de blog, les vidéos, etc. est partagé sur les plateformes de médias sociaux - Facebook, LinkedIn ou X.',
            value: 10,
          },
          {
            name: "L'inscription en ligne après confirmation de l'intérêt pour une démo ou des essais facilite l'acquisition et les achats de clients potentiels.",
            value: 10,
          },
          {
            name: "La commande et l'achat en ligne sont activés.",
            value: 20,
          },
          { name: 'Aucune des réponses ci-dessus.', value: 0 },
        ],
      },
      {
        qtxt: "Veuillez évaluer l'utilisation de techniques de marketing et de publicité telles que le référencement, le PPC, la vidéo et le courrier électronique. Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '1e',
        questions: [
          {
            name: 'Mon entreprise possède un compte Google AdWords et met activement en œuvre des campagnes PPC, Mots clés ou Display.',
            value: 20,
          },
          { name: 'Mon entreprise possède une chaîne YouTube.', value: 20 },
          {
            name: "Mon entreprise dispose d'une plateforme de messagerie, d'une liste active de destinataires et envoie un e-mail au moins une fois par mois.",
            value: 20,
          },
          {
            name: 'Mon entreprise crée du contenu vidéo et le publie au moins 6 fois par an.',
            value: 20,
          },
          {
            name: 'Le SEO est une approche stratégique pour mon entreprise.',
            value: 20,
          },
          { name: 'Aucune des réponses ci-dessus.', value: 0 },
        ],
      },
    ];
    this.secondSection = [
      {
        qtxt: 'Évaluez le niveau de la stratégie marketing, de la planification et de la mise en œuvre de votre entreprise. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:',
        q: '2a',
        questions: [
          {
            name: "Le marketing augmente les revenus et l'efficacité potentielle de mon entreprise.",
            value: 100,
          },
          {
            name: "Le marketing aide à générer des prospects pour l'entreprise.",
            value: 75,
          },
          {
            name: "Une gamme d'activités de marketing sont exécutées, souvent personnalisées pour le contenu numérique.",
            value: 50,
          },
          {
            name: 'Une stratégie marketing et une planification visionnaire sont mises en œuvre.',
            value: 25,
          },
          {
            name: "Il n'y a pas de stratégie marketing spécifique.",
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Évaluez la structure et la disponibilité des ressources marketing au sein de votre entreprise. Avez-vous actuellement des employés marketing dédiés ou une agence partenaire ? Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:',
        q: '2b',
        questions: [
          {
            name: "Mon entreprise ne dispose d'aucune ressource marketing.",
            value: 0,
          },
          {
            name: "Mon entreprise a des employés à temps partiel ou des ressources marketing limitées ou ces ressources sont partagées avec d'autres fonctions.",
            value: 30,
          },
          {
            name: "Mon entreprise dispose d'une personne (ou d'une petite équipe) comme ressources marketing dédiées.",
            value: 70,
          },
          {
            name: "Mon entreprise dispose de ressources marketing dédiées (plus d'une personne) et d'une agence partenaire.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Comment décririez-vous le niveau d’engagement de votre entreprise envers le marketing et en particulier le marketing numérique ? Évaluez le soutien de la haute direction. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:',
        q: '2c',
        questions: [
          {
            name: 'Pas du tout engagé. Il n’y a aucun soutien de la part de la haute direction.',
            value: 0,
          },
          {
            name: 'Pas très engagé. La direction fournit un soutien verbal uniquement et un soutien et des ressources insuffisantes.',
            value: 25,
          },
          {
            name: 'Plutôt engagé. Il existe un support technique de la haute direction et un plan en place.',
            value: 50,
          },
          {
            name: "Très engagé. La haute direction fournit un parrainage et une feuille de route d'investissement modérée.",
            value: 75,
          },
          {
            name: 'Extrêmement engagé. Soutien exceptionnel de la haute direction et engagement budgétaire.',
            value: 100,
          },
        ],
      },
      {
        qtxt: "Veuillez évaluer la disponibilité et l'utilisation de votre plan marketing et d'autres ressources. Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '2d',
        questions: [
          {
            name: "Mon entreprise n'a pas de plan marketing en place mais met en œuvre certaines activités marketing de temps en temps.",
            value: 10,
          },
          {
            name: "Mon entreprise a mis en place un plan marketing initial, mais il n'est pas encore mis en œuvre.",
            value: 10,
          },
          {
            name: 'Le service marketing soutient mon équipe commerciale avec des informations complètes et un contenu interactif et intéressant.',
            value: 20,
          },
          {
            name: "Connaissance de l'industrie et des concurrents. Il existe une analyse concurrentielle de mon entreprise par rapport à vos concurrents dans leur marketing et leur génération de leads.",
            value: 20,
          },
          {
            name: "Le service marketing dispose de suffisamment d'outils marketing (logiciels) et de ressources externalisées pour mettre en œuvre des campagnes.",
            value: 20,
          },
          {
            name: 'Il existe un budget spécifique à investir dans les activités de marketing pour la croissance.',
            value: 20,
          },
          {
            name: 'Aucune des réponses ci-dessus.',
            value: 0,
          },
        ],
      },
      {
        qtxt: "Évaluez les outils et systèmes marketing en place au sein de votre entreprise pour tirer parti de l’engagement (prospect) des clients. Veuillez sélectionner l'expression qui décrit le mieux votre entreprise :",
        q: '2e',
        questions: [
          {
            name: 'Marketing axé sur les relations avec engagement direct (face à face) et réseautage à travers des événements. Faible niveau d’initiatives de marketing numérique et sur les réseaux sociaux.',
            value: 25,
          },
          {
            name: "Établir des relations principalement par le biais d'initiatives d'engagement client telles que des événements et les médias traditionnels. De plus en plus adaptatif aux plateformes et canaux de communication numériques.",
            value: 50,
          },
          {
            name: 'Les outils traditionnels et numériques sont également exploités pour engager les clients potentiels et renforcer de nouveaux modèles commerciaux.',
            value: 75,
          },
          {
            name: 'Les actifs de marketing numérique tels que les e-mails ou les pages de destination, et la communication sur les réseaux sociaux sont les outils essentiels pour renforcer de nouvelles relations et de nouveaux modèles commerciaux.',
            value: 100,
          },
        ],
      },
    ];
    this.thirdSection = [
      {
        qtxt: 'Évaluez votre proposition de valeur client par rapport à vos concurrents.',
        q: '3a',
        questions: [
          {
            name: 'Mon entreprise a une proposition de valeur client (CVP) bien définie',
            value: 25,
          },
          {
            name: 'Notre proposition de valeur client est souhaitable (répond-elle aux besoins des clients?)',
            value: 25,
          },
          {
            name: "Notre proposition de valeur client est distinctive (en quoi se démarque-t-elle des CVP d'autres entreprises?)",
            value: 25,
          },
          {
            name: "Notre proposition de valeur client est défendable (de quels « éléments de preuve » avons-nous qu'elle fera ce que nous disons?)",
            value: 25,
          },
        ],
      },
      {
        qtxt: 'Évaluez votre positionnement et le niveau de force de votre marketing par rapport à vos concurrents.',
        q: '3b',
        questions: [
          {
            name: 'Mon entreprise a un positionnement bien défini.',
            value: 25,
          },
          {
            name: 'Mon entreprise/marque est différente et distinctive par rapport à nos trois concurrents principaux (la différenciation consiste à maximiser la différence perçue entre nous et nos concurrents.)',
            value: 25,
          },
          {
            name: "Notre positionnement est associé à des valeurs fondamentales (pour l'industrie) et possède une stratégie de message claire.",
            value: 25,
          },
          {
            name: "Notre proposition de valeur client bien définie comporte le facteur de pertinence. (La pertinence consiste à maximiser l'adéquation perçue entre nous et les besoins de nos clients.)",
            value: 25,
          },
        ],
      },
      {
        qtxt: 'Évaluez votre façon de travailler et évaluez le contenu marketing de votre entreprise et son efficacité. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:',
        q: '3c',
        questions: [
          {
            name: 'Peu efficace. Le contenu est créé rarement ou de manière ponctuelle. Nous utilisons le contenu de nos partenaires fournisseurs (par exemple, nous avons accès à leurs outils de synchronisation de contenu marketing.)',
            value: 25,
          },
          {
            name: 'Modérément efficace. Création de contenu de base tel que des newsletters électroniques, des articles, des blogs, des études de cas, des webinaires) produit principalement pour des campagnes et des activités tactiques (et du contenu tiers de partenaires/fournisseurs le cas échéant.)',
            value: 50,
          },
          {
            name: "Efficace. Un référentiel du nouveau contenu créé par l'entreprise ainsi que du contenu tiers des partenaires/fournisseurs est en place et utilisé pour stimuler la croissance.",
            value: 75,
          },
          {
            name: "Extrêmement efficace. Le contenu original attrayant est créé et cartographié en fonction de l'étape et de la personnalité du parcours client [par exemple, en définissant des objectifs de contenu (leadership éclairé, établir un consensus, confirmer la valeur, etc.) à l'aide de mesures clés à évaluer (par exemple, le trafic, les partages, les téléchargements, les conversions, l'attribution des revenus).",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Évaluez la cartographie de votre parcours client (customer journey) en fonction de son adéquation à votre contenu et de son efficacité à générer des ventes. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise :',
        q: '3d',
        questions: [
          {
            name: "Aucun parcours client n'est enregistré.",
            value: 25,
          },
          {
            name: "Un Parcours Client est mis en place avec des phases de base identifiées (sans avoir l'acceptation de l'équipe commerciale.)",
            value: 50,
          },
          {
            name: 'Un parcours client bien défini avec la cartographie du contenu exploité par les équipes marketing et commerciales.',
            value: 75,
          },
          {
            name: 'Un parcours client entièrement structuré, cartographié avec le contenu approprié à chaque étape, générant des ventes pour notre entreprise.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Comment évalueriez-vous la force de votre stratégie dans chacun de ces domaines :',
        q: '3e',
        questions: [
          {
            name: "Capacités d'automatisation du marketing/MarTech",
            value: 20,
          },
          {
            name: 'Publicité numérique',
            value: 20,
          },
          {
            name: 'Marketing direct et par courrier électronique',
            value: 20,
          },
          {
            name: 'Un événement marketing',
            value: 20,
          },
          {
            name: 'Marketing des médias sociaux',
            value: 20,
          },
        ],
      },
    ];
    this.forthSection = [
      {
        qtxt: 'Évaluez la valeur de votre base de données marketing qui peut stimuler la segmentation et les performances. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise :',
        q: '4a',
        questions: [
          {
            name: "Nous utilisons une base de données marketing limitée (liste hors ligne d'anciens e-mails, etc.)",
            value: 25,
          },
          {
            name: 'Une base de données centralisée (de faible taille et de faible qualité) a été mise en place avec des segments de base identifiés.',
            value: 50,
          },
          {
            name: "Une base de données segmentée (de taille et de qualité moyenne), capable de localiser des cibles potentielles d'up-sell/cross-sell, a été mise en place.",
            value: 75,
          },
          {
            name: 'Une base de données segmentée avancée de haute qualité a été mise en place sur la base de données firmographiques (données démographiques B2B), comportementales, besoins et historiques pour soutenir les parcours clients de bout en bout.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Évaluez les capacités de votre plateforme de gestion de la relation client. Veuillez sélectionner la phrase qui décrit le mieux votre entreprise:',
        q: '4b',
        questions: [
          {
            name: "Les contacts commerciaux, le pipeline et l'entonnoir sont réalisés via diverses applications, sans la présence d'une plateforme CRM centrale.",
            value: 25,
          },
          {
            name: "Utilisation de processus de métriques et d'analyses essentiellement personnalisés, tout en mettant en œuvre une base de données de contacts.",
            value: 50,
          },
          {
            name: 'Notre plateforme CRM a été organisée avec des processus clairement identifiés et des indicateurs de performance suivis.',
            value: 75,
          },
          {
            name: "Notre plateforme CRM a été organisée, intégrée aux systèmes d'expérience client. Les systèmes de gestion de la relation client de l'entreprise fonctionnent bien et fournissent des mises à jour régulières des données clients.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Évaluez votre gestion des leads pour la qualification et la notation. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:',
        q: '4c',
        questions: [
          {
            name: 'Limité. Il n’y a pas de critères définis pour qualifier les prospects.',
            value: 25,
          },
          {
            name: 'Suffisant avec les définitions et les objectifs des étapes principales. Les processus de lead nurturing et de qualification des leads sont définis, bien que peu utilisés.',
            value: 50,
          },
          {
            name: "Bien. Les leads sont générés, qualifiés et comptabilisés tout au long du processus de vente. Des mesures telles que la taille moyenne de la transaction lors de la création de l'opportunité par rapport à la clôture de l'opportunité, CLV, COCA sont disponibles.",
            value: 75,
          },
          {
            name: "Excellent. Les leads sont comptés et mesurés dans un logiciel d'automatisation du marketing, enrichis de données provenant d'autres canaux et événements numériques et sociaux, de webinaires, etc.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Évaluez la gestion de votre pipeline dans les domaines des ventes et du marketing. Veuillez sélectionner la phrase qui décrit le mieux votre entreprise:',
        q: '4d',
        questions: [
          {
            name: 'Progression limitée du pipeline. Les pipelines marketing et commerciaux sont gérés avec des outils personnalisés, sans utiliser de plateforme CRM.',
            value: 25,
          },
          {
            name: 'Moyenne. Les rapports de pipeline sont basés sur la base de données CRM, sans grande précision en raison de la qualité limitée des données et/ou du manque de métriques clairement définies.',
            value: 50,
          },
          {
            name: "Suffisant. Les rapports de pipeline incluent des données de haute qualité (même s'il n'y a pas de mises à jour continues) et le suivi des KPI est effectué sur une base personnalisée.",
            value: 75,
          },
          {
            name: "Supérieur. Les rapports sur le pipeline sont automatiquement mis à jour et définis de manière adéquate tout au long du processus de vente, orientant les activités de l'équipe pour des résultats commerciaux optimaux.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Évaluez et coter à quel point les équipes commerciales et marketing sont étroitement alignées sur ce qui constitue un prospect qualifié et leur alignement pour stimuler la croissance. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise :',
        q: '4e',
        questions: [
          {
            name: "Désalignement. L'entreprise comprend naturellement les caractéristiques d'un lead qualifié, mais il existe des conflits entre les équipes commerciales et marketing et la coordination-coopération entre elles est très faible.",
            value: 25,
          },
          {
            name: 'Faible alignement. Les vendeurs se plaignent souvent que leurs leads ne sont pas assez bons. Les spécialistes du marketing ne savent pas quels actifs sont les plus utiles à leurs collègues commerciaux. Effort supplémentaire requis pour bénéficier de l’alignement ventes-marketing.',
            value: 50,
          },
          {
            name: "Alignement « Plutôt ». Les deux équipes tentent de s'aligner comme l'indiquent le taux de croissance et l'augmentation des revenus.",
            value: 75,
          },
          {
            name: "Alignement complet. Les deux équipes ont des objectifs communs et un document écrit décrivant ce qu'est un MQL. Les équipes sont entièrement alignées et l'entreprise gagne une valeur de commande plus élevée grâce aux prospects nourris.",
            value: 100,
          },
        ],
      },
    ];
    this.fifthSection = [
      {
        qtxt: 'Découvrez comment vos analyses marketing sont organisées. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise:',
        q: '5a',
        questions: [
          {
            name: "L'utilisation des analyses dans mon entreprise est limitée (par exemple, reporting par courrier électronique de niveau standard ou collecte de données Web).",
            value: 25,
          },
          {
            name: 'Un fournisseur externe fournit régulièrement un support analytique personnalisé.',
            value: 50,
          },
          {
            name: "Les données analytiques d'activités comparables (telles qu'une série de campagnes passées) sont traitées pour évaluer l'efficacité du marketing, sans déterminer la stratégie commerciale de l'entreprise.",
            value: 75,
          },
          {
            name: "Les analyses ont un impact profond sur la planification et la prise de décision de l'entreprise sur tous les canaux marketing.",
            value: 100,
          },
        ],
      },
      {
        qtxt: "Explorez la configuration des mesures de marketing et d’expérience client de votre entreprise. Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '5b',
        questions: [
          {
            name: "Indicateurs de fidélisation des clients tels que NPS (Net Promoter Score), satisfaction client, probabilité d'achat, effort client, etc.",
            value: 25,
          },
          {
            name: 'Mesures de qualité telles que les taux de défaillance des produits, la durée de vie du produit, le coût de maintenance, etc.',
            value: 25,
          },
          {
            name: "Mesures de coûts et d'efficacité des campagnes telles que les taux de coûts, le coût par prospect, les impressions par rapport aux références du secteur, le coût d'acquisition de nouveaux clients, etc.",
            value: 25,
          },
          {
            name: 'Mesures de rapidité telles que la livraison à temps, la rapidité de réponse aux demandes, la rapidité de réponse aux plaintes, etc.',
            value: 25,
          },
          {
            name: 'Aucune des réponses ci-dessus.',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Évaluez dans quelle mesure vous avez bien défini vos objectifs marketing et comment les KPI (succès-échec) seront mesurés. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise :',
        q: '5c',
        questions: [
          {
            name: 'Nos objectifs et KPI/mesures ne sont pas correctement identifiés.',
            value: 25,
          },
          {
            name: 'Nous nous concentrons sur les KPI de campagne à court terme.',
            value: 50,
          },
          {
            name: "Nous nous concentrons sur une combinaison de mesures liées aux campagnes et aux clients sur la base d'un plan et analysons régulièrement nos données pour mesurer l'efficacité.",
            value: 75,
          },
          {
            name: "Nous utilisons une carte de pointage pour regrouper les sources de données dans un seul tableau de bord à des fins de suivi et d'analyse et déployons diverses mesures établies (mentionnées précédemment sur Metrics) en tant que KPI.",
            value: 100,
          },
        ],
      },
      {
        qtxt: "Auditez la disponibilité et l’utilisation de votre technologie marketing pour améliorer les performances. Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '5d',
        questions: [
          {
            name: "Contacts. Bases de données et génération/nurturing de leads - outils d'aide à la vente.",
            value: 20,
          },
          {
            name: 'Connexions - Visiteurs. Logiciels et outils sociaux, sites Web, courrier électronique, interactifs et de remarketing.',
            value: 20,
          },
          {
            name: "Contenu. Outils et logiciels pour la création, l'optimisation et la notation de contenu.",
            value: 20,
          },
          {
            name: "Données client. Solutions opérationnelles Go-To-Market, logiciels de gestion des relations et services d'analyse.",
            value: 20,
          },
          {
            name: 'Utilisation de la plateforme Marketing Automation.',
            value: 20,
          },
        ],
      },
    ];
    this.sixthSection = [
      {
        qtxt: "Découvrez comment le plan de campagne de votre entreprise est mis en œuvre et évaluez les phases régulièrement définies dans votre plan de campagne marketing. Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '6a',
        questions: [
          {
            name: "Analyse. Besoins commerciaux, segmentation avancée avec cartographie du marché, identification des critères de décision d'achat clés et découverte et validation des segments à cibler, informations et idées. Définition de la stratégie, des priorités et des actions pour toucher le Buyer Persona.",
            value: 15,
            id: 'inlineRadio1',
          },
          {
            name: "Établissement d'objectifs. Objectifs commerciaux, buts de campagne et KPI. (Qu’essayez-vous d’atteindre grâce à votre campagne et comment saurez-vous quand vous y parviendrez ?)",
            value: 15,
            id: 'inlineRadio2',
          },
          {
            name: 'Contenu. Messages créatifs et clés. (Comment essayez-vous de positionner votre entreprise, vos produits et services ?) Production d’actifs de campagne. (Gérer le contenu et les actifs pour former la campagne.)',
            value: 15,
            id: 'inlineRadio3',
          },
          {
            name: "Ciblage. Les campagnes sont affinées pour s'adresser à des zones géographiques, des secteurs, des marchés en croissance, des clients et des canaux existants. Le super-ciblage est utilisé pour le ciblage des campagnes (par exemple avec une base de données CRM intégrée, des mécanismes de notation et un profilage avancé avec les Buyer Personas.)",
            value: 15,
            id: 'inlineRadio3',
          },
          {
            name: 'Plan média et budget de la campagne (quels canaux médiatiques utiliseriez-vous pour atteindre et influencer votre public cible ?)',
            value: 20,
            id: 'inlineRadio3',
          },
          {
            name: 'Exécution. (Que faut-il tester avant que la campagne ne soit lancée et ajustée ?)',
            value: 10,
            id: 'inlineRadio3',
          },
          {
            name: 'Suivi. Évaluation post-exécution. Effectuer une analyse de la campagne et la modifier pour améliorer les performances globales si nécessaire.',
            value: 10,
            id: 'inlineRadio3',
          },
          {
            name: 'Aucune des réponses ci-dessus.',
            value: 0,
            id: 'inlineRadio3',
          },
        ],
      },
      {
        qtxt: 'Évaluez le niveau de l’environnement interne (forces et faiblesses) et externe (menaces-opportunités) de votre entreprise. Veuillez sélectionner la déclaration qui décrit le mieux votre entreprise :',
        q: '6b',
        questions: [
          {
            name: "Aucune analyse SWOT ou concurrentielle n'est enregistrée.",
            value: 25,
            id: 'inlineRadio4',
          },
          {
            name: 'Nous avons une analyse SWOT en cours.',
            value: 50,
            id: 'inlineRadio5',
          },
          {
            name: 'Nous avons mis en place une analyse SWOT.',
            value: 75,
            id: 'inlineRadio6',
          },
          {
            name: "L'entreprise dispose d'une analyse SWOT ainsi que d'autres résultats de recherche à utiliser dans le processus de planification marketing.",
            value: 100,
            id: 'inlineRadio7',
          },
        ],
      },
      {
        qtxt: "Auditer et évaluer la valeur et la valeur marchande de vos produits/services ? Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '6c',
        questions: [
          {
            name: 'Mon entreprise propose des produits/services dotés de caractéristiques uniques qui les aident à se démarquer ou à rivaliser en termes de prix par rapport à la qualité perçue.',
            value: 20,
          },
          {
            name: "Nous proposons des produits/services que les clients sont motivés à acheter parce qu'ils répondent à leurs besoins.",
            value: 20,
          },
          {
            name: 'Mon entreprise a défini un ICP (Ideal Customer Profile) et des personnalités ainsi que "à qui s\'adresse-t-il".',
            value: 20,
          },
          {
            name: "La valeur de nos offres pour le client inclut des facteurs de qualité tels que la fiabilité, l'expérience client, la productivité, la disponibilité, la convivialité, etc.",
            value: 20,
          },
          {
            name: 'Nos produits/services peuvent aider les clients à résoudre un problème qui rend leur vie ou leur travail plus pratique.',
            value: 20,
          },
          {
            name: 'Aucune des réponses ci-dessus.',
            value: 0,
          },
        ],
      },
      {
        qtxt: "Analysez la manière dont votre entreprise budgétise et priorisez l’investissement. Lesquels d’entre eux sont régulièrement importants et utilisés dans votre budget marketing ? Veuillez sélectionner tout ceux qui s'appliquent.",
        q: '6d',
        questions: [
          {
            name: 'Site web',
            value: 20,
          },
          {
            name: 'Médias payants',
            value: 15,
          },
          {
            name: 'Réseaux sociaux',
            value: 15,
          },
          {
            name: 'Création de contenu',
            value: 10,
          },
          {
            name: 'Événements/conférences/webinaires',
            value: 10,
          },
          {
            name: 'Technologie/analyse marketing',
            value: 10,
          },
          {
            name: 'Incitatifs/Autres',
            value: 10,
          },
          {
            name: "Création d'entreprise/de marque",
            value: 10,
          },
        ],
      },
    ];

    this.rec1 = [
      "Commencez à prendre davantage soin de la présence de votre entreprise pour la rendre plus extravertie. Utilisez le contenu et les actifs marketing de vos affiliés/partenaires/fournisseurs et créez votre propre contenu de médias sociaux qui vous aidera lentement à créer vos propres canaux de médias sociaux (si vous n'en avez pas). Pensez également à organiser des événements physiques ou digitaux pour vos clients et prospects.",
      "Développez davantage votre identité de marque en enrichissant le contenu de votre site Web, en ayant un impact sur le numérique et en organisant des événements réussis, pour répondre aux besoins quotidiens de votre entreprise. Développez un plan marketing qui convient et reflète votre entreprise en valorisant votre image de marque, vos produits/solutions, tout en mettant l'accent sur votre proposition de valeur client.",
      "Vous disposez d'une présence marketing extravertie décente que vous devez développer davantage en explorant des solutions qui rendent votre entreprise « plus numérique » et renforcent vos produits, le canal de distribution et le contenu marketing (par exemple, syndication de contenu avec votre partenaire/fournisseurs). Découvrez des solutions et des applications utiles ou discutez avec votre agence de marketing de votre développement futur dans le domaine du marketing.",
      "Améliorez votre présence marketing déjà dynamique en créant votre propre contenu authentique qui ajoutera de la valeur à vos clients et explorez une segmentation plus approfondie de vos utilisateurs/clients, afin que vous puissiez adapter votre contenu aux personnalités et aux besoins des acheteurs. La technologie marketing et une stratégie de croissance cohérente permettent à vos opérations marketing de l'emporter sur vos concurrents et de tirer parti d'un avantage concurrentiel clé.",
    ];
    this.rec2 = [
      'Planifier et organiser le service marketing (ou votre entreprise) vous aidera à prendre les bonnes mesures pour améliorer votre position. Profitez des données de cette étude et discutez au sein de votre entreprise de la manière dont vous pouvez évoluer pour répondre aux exigences de cette nouvelle normalité.',
      "Tirez parti de ce cadre d'évaluation du marketing B2B et discutez du score de votre entreprise avec vos membres clés, en élaborant la bonne stratégie de marketing B2B au profit de votre entreprise. Discours sur les campagnes marketing efficaces auprès des commerciaux et des vendeurs pour leur montrer le retour sur investissement et la nécessité de s'aligner sur une « nécessité » marketing.'. ",
      "Planifiez des activités ou des campagnes marketing qui démontreront leur succès et répondront à des critères prédéfinis. Ces programmes devraient faire partie de votre plan de campagne à long terme. Surveillez et maintenez la compétence, les connaissances et la rapidité de votre équipe commerciale pour s'adapter aux nouvelles possibilités créées par ces campagnes. ",
      'Le marketing doit être considéré comme un contributeur substantiel à l’augmentation des revenus de l’entreprise. Continuez à développer une culture marketing et numérique à l’échelle de l’entreprise, en améliorant votre positionnement, votre notoriété et en montrant à vos clients que vous êtes une entreprise moderne.',
    ];
    this.rec3 = [
      'Travaillez de toute urgence avec votre agence pour définir certaines étapes marketing essentielles qui constituent des piliers stables pour le marketing de votre entreprise.',
      'Assurez-vous que votre plan marketing, vos opérations marketing et vos activités marketing sont tous alignés sur les objectifs, les priorités et les besoins de votre entreprise. Impliquez votre équipe de vente et de développement commercial pour obtenir un effet de levier et une exécution ultérieure.',
      "Essayez de tirer davantage parti de vos éléments marketing fondamentaux. Pousser l’analyse plus loin en s’appuyant sur les enseignements tirés de la recherche et des enquêtes. Travaillez en harmonie avec votre équipe de vente pour développer une compréhension approfondie de votre comportement d'acheteur tout au long du parcours en ligne des clients, afin que vous puissiez mieux faire évoluer la proposition de valeur client, les valeurs et le positionnement de votre entreprise par rapport à vos concurrents.",
      "Vous êtes un spécialiste du marketing sophistiqué avec une création de contenu originale et votre entreprise dispose d'une base marketing stable. Améliorez l'essence de vos fondamentaux marketing déjà abordés en mesurant votre expérience client avec des enquêtes étendues (en ajoutant de nouvelles mesures) et en développant votre propre contenu authentique qui ajoutera de la valeur à vos clients. Mener de nouveaux efforts d'optimisation des campagnes sur la base des enseignements des campagnes passées et appliquer ces apprentissages à la planification des campagnes futures.",
    ];
    this.rec4 = [
      "Enquêtez et détectez les conditions qui favorisent l’installation d’une plateforme CRM dans votre entreprise. Pour des résultats optimaux tout en vous y adaptant, essayez d'impliquer les équipes commerciales et marketing et laissez-les contribuer avec des idées et des moyens pour sa bonne utilisation.",
      "Pour des résultats optimaux tout en vous adaptant à votre plateforme CRM, essayez d'impliquer les équipes commerciales et marketing et laissez-les contribuer avec des idées et des moyens pour sa bonne utilisation. Planifiez et définissez les processus et les flux de travail dans les deux départements (Ventes et Marketing) et intégrez tous les autres outils, même les processus manuels que vous utilisez, dans le but de transformer les leads en opportunités. Le parcours client doit être la feuille de route sur ce que vous voulez que votre CRM fasse pour vous et lancer votre stratégie CRM.",
      "L'alignement ventes-marketing et le CRM ne sont pas des outils plug-and-play que vous ajoutez simplement à votre entreprise. Ils doivent être situés au cœur de votre activité. Le bon niveau d’alignement de l’équipe Ventes-Marketing et l’enregistrement détaillé des procédures dans CRM jettent les bases d’une fonction CRM réussie et de son adoption au sein de l’entreprise comme seul moyen de générer des leads et de développer la croissance avec succès. Fixez les limites d'un processus automatisé pour contrôler l'évolution de vos leads grâce à des directives concrètes, partagées entre les équipes commerciales et marketing.",
      "Les équipes commerciales et marketing sont entièrement alignées, la qualification et la notation des leads sont entièrement automatisées et les rapports sur le pipeline sont entièrement mis à jour et automatisés. Ainsi, l’entreprise gagne une valeur de commande plus élevée grâce aux prospects nourris. L'adoption et la bonne utilisation de votre plateforme CRM développeront des flux marketing qui nourriront, vendront automatiquement, vendront de manière croisée et acquerront des clients, générant ainsi de meilleurs résultats commerciaux.",
    ];
    this.rec5 = [
      "L'analyse comparative, les mesures et les analyses sont importantes en entreprise. Fournissez des points de référence par rapport auxquels vous pouvez mesurer vos performances et une fonction pré-requise pour concevoir une stratégie et prendre des décisions. Utilisez des mesures et des analyses pour évaluer les performances de vos initiatives et générer des améliorations continues. De nos jours, les capacités martech doivent être considérées comme un outil nécessaire pour faire des affaires et nous nous familiarisons tous avec cette perspective.",
      "Essayez d'adopter une « monnaie » marketing pour vos campagnes marketing. Définissez un ensemble de mesures et de tableaux de bord marketing qui constitueront la « monnaie » de votre entreprise pour comprendre les résultats de planification, d'exécution et de performance de vos initiatives numériques. Utilisez des données mesurées en fonction de vos objectifs commerciaux, telles que les informations sur les visiteurs, les sous-groupes d'audience, les modèles de trafic, les référents, les transmissions, les conversions, les fonctionnalités du pipeline, le retour sur investissement numérique, etc. Il est crucial d'entrer dans la nouvelle réalité B2B.",
      "Les KPI affichent une valeur mesurable qui montre la progression des objectifs commerciaux de votre entreprise. L'analyse peut vous aider à évaluer les performances de votre entreprise pour vous assurer de générer continuellement de la valeur et d'identifier les opportunités d'amélioration des performances. Suivez vos progrès grâce à vos objectifs mesurables et capitalisez-en pour apporter des améliorations critiques et des décisions opportunes concernant vos projets à venir. Votre entreprise est prête à aller de l’avant, sur la base d’une gestion décisionnelle basée sur les données.",
      'Vous êtes déjà une entreprise axée sur les données. Continuez à améliorer et à explorer vos avantages acquis pour comprendre pleinement le comportement des acheteurs tout au long du parcours client en ligne, ainsi que pour façonner une image actuelle des tendances du marché. Vous serez dans une position privilégiée par rapport à vos concurrents en ce qui concerne votre stratégie commerciale et votre processus décisionnel.',
    ];

    this.rec6 = [
      'Engagez-vous avec les membres et départements clés de votre entreprise pour remodeler vos priorités actuelles et futures, avant de commencer la planification.',
      'Assurez-vous que votre plan marketing, vos opérations marketing et vos activités marketing sont tous alignés sur les objectifs, les priorités et les besoins de votre entreprise. Impliquez votre équipe de vente et de développement commercial pour obtenir un effet de levier et une exécution ultérieure.',
      "En quête de succès, mais confrontée au défi de l'intégration, votre entreprise est en train de croître. Élaborez un parcours de campagne à long terme, en alignant votre stratégie de marque sur des messages et un positionnement de grande envergure. Tenez compte des tendances à venir du secteur et des moyens de créer des campagnes répondant à ces nouvelles opportunités attendues. Vous pouvez désormais augmenter le budget de certaines campagnes, tester leur réactivité et les résultats en termes de revenus ou de génération de leads. Vous pouvez également envisager d’augmenter vos ressources marketing avec des collègues supplémentaires ou de trouver une agence experte pour vous aider.",
      "Vous êtes dans une position marketing saine et votre entreprise dispose d’opérations marketing et d’un niveau de planification de campagne stables. Votre entreprise est en pleine croissance. Vous pouvez désormais augmenter le budget de certaines campagnes, tester leur réactivité et les résultats en termes de revenus ou de génération de leads. Grâce à une compréhension plus approfondie du comportement des clients, votre entreprise peut évoluer vers un modèle prédictif dans lequel les actions des clients peuvent être anticipées bien avant qu'elles ne se produisent. Cela vous permet de transmettre des messages ciblés aux sous-segments censés se comporter de manière prédite, tout en analysant le comportement du public dans son ensemble.",
    ];
  }
  // / constr

  returnScores() {
    const scores = [
      this.firstScore,
      this.secondScore,
      this.thirdScore,
      this.forthScore,
      this.fifthScore,
      this.sixthScore,
    ];
    return scores;
  }

  setFirstScore(score) {
    this.firstScore = score;
  }
  setSecondScore(score) {
    this.secondScore = score;
  }
  setThirdScore(score) {
    this.thirdScore = score;
  }
  setForthScore(score) {
    this.forthScore = score;
  }
  setFifthScore(score) {
    this.fifthScore = score;
  }
  setSixthScore(score) {
    this.sixthScore = score;
  }
}
