<div class="periexomeno">
  <div class="iftest d-print-none" *ngIf="testpage">
    This is a test page so mock data apply, this text doesn't show on print or
    any other url
  </div>
  <div class="banner d-print-none">
    <div class="stararea"></div>
    <div class="areas">
      <div
        class="slogan"
        [attr.your-custom]="'assessment.subtitle' | translate"
      >
        <h1>{{ "assessment.overall" | translate }}</h1>
      </div>
    </div>
    <div class="slogan">
      <h5>{{ "assessment.subtitle2" | translate }}</h5>
    </div>
    <div class="pattern"></div>
  </div>
  <div
    [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    class="bannerprint d-print-block"
  ></div>
  <div class="stoixeiaCompany d-print-block">
    <div class="company">
      {{ company }}
    </div>
    <!-- <div class="date">
      {{ today | date : "dd/MM/yyyy" }}
    </div> -->
  </div>
</div>
<div class="pagebreak"></div>

<div
  [ngStyle]="{ 'background-image': 'url(' + topfasa + ')' }"
  class="topfasa"
></div>

<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="aboveBar">
      <h1 class="stage">{{ stringScore }}</h1>
      <div class="icons d-print-none">
        <div
          placement="top"
          ngbTooltip="Share page"
          class="shareicon d-print-none"
          (click)="sharePage()"
        ></div>
        <div class="printicon d-print-none" (click)="printPage()"></div>
      </div>
    </div>
    <div class="summary">
      <b>{{ scoreTitle }}</b> - {{ scoreMessage }}
    </div>
    <div class="desc">{{ "assessment.yourScore" | translate }}</div>
    <div class="progressbaroverall">
      <ngb-progressbar
        type="primary"
        [value]="totalScore"
        [striped]="false"
      ></ngb-progressbar>
      <div class="totalscore">{{ totalScore | number : "1.2-2" }}</div>
    </div>

    <div class="twocols">
      <div class="chartarea">
        <div id="chart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [fill]="chartOptions.fill"
            [stroke]="chartOptions.stroke"
            [responsive]="chartOptions.responsive"
            [legend]="chartOptions.legend"
            [colors]="chartOptions.colors"
            [yaxis]="chartOptions.yaxis"
          ></apx-chart>
        </div>
      </div>
      <div class="scorevalues">
        <h3>{{ "assessment.score" | translate }}</h3>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[0]"
        >
          <div class="icon onep"></div>
          <div class="actualScore one">
            {{ firstScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[1]"
        >
          <div class="icon twop"></div>
          <div class="actualScore two">
            {{ secondScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[2]"
        >
          <div class="icon threep"></div>
          <div class="actualScore three">
            {{ thirdScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[3]"
        >
          <div class="icon fourp"></div>
          <div class="actualScore four">
            {{ forthScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[4]"
        >
          <div class="icon fivep"></div>
          <div class="actualScore five">
            {{ fifthScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[5]"
        >
          <div class="icon sixp"></div>
          <div class="actualScore six">
            {{ sixthScore }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bottomfasa"></div>

<div class="pagebreak"></div>

<div
  [ngStyle]="{ 'background-image': 'url(' + topfasa + ')' }"
  class="topfasa"
></div>
<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="recommendations">
      <h1>{{ "assessment.recomendations" | translate }}</h1>
      <p>
        {{ "assessment.apartFrom" | translate }}
      </p>
      <div class="rec">
        <div class="rect oneb">{{ firstScore | number : "1.0-0" }}</div>
        <div class="rectxt">
          <div class="recTitle one">
            {{ "assessment.oneTitle" | translate }}
          </div>
          <div class="recDesc">
            {{ recommendation1 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect twob">{{ secondScore | number : "1.0-0" }}</div>
        <div class="rectxt">
          <div class="recTitle two">
            {{ "assessment.twoTitle" | translate }}
          </div>
          <div class="recDesc">
            {{ recommendation2 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect threeb">{{ thirdScore | number : "1.0-0" }}</div>
        <div class="rectxt">
          <div class="recTitle three">
            {{ "assessment.threeTitle" | translate }}
          </div>
          <div class="recDesc">
            {{ recommendation3 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect fourb">{{ forthScore | number : "1.0-0" }}</div>
        <div class="rectxt">
          <div class="recTitle four">
            {{ "assessment.forthTitle" | translate }}
          </div>
          <div class="recDesc">
            {{ recommendation4 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect fiveb">{{ fifthScore | number : "1.0-0" }}</div>
        <div class="rectxt">
          <div class="recTitle five">
            {{ "assessment.fifthTitle" | translate }}
          </div>
          <div class="recDesc">
            {{ recommendation5 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect sixb">{{ sixthScore | number : "1.0-0" }}</div>
        <div class="rectxt">
          <div class="recTitle six">
            {{ "assessment.sixthTitle" | translate }}
          </div>
          <div class="recDesc">
            {{ recommendation6 }}
          </div>
        </div>
      </div>
    </div>
    <div class="nextSteps d-print-none">
      <div class="title">{{ "assessment.nextSteps" | translate }}</div>
      <div class="descr">{{ "assessment.stepsSub" | translate }}</div>
      <div class="points">
        <ul>
          <li>
            {{ "assessment.point1" | translate }}
          </li>
          <li>
            {{ "assessment.point2" | translate }}
          </li>
          <li>
            {{ "assessment.point3" | translate }}
          </li>
          <li>
            {{ "assessment.point4" | translate }}
          </li>
        </ul>
      </div>
      <div class="copyright">
        {{ "assessment.copyright1" | translate }}<br />
        {{ "assessment.copyright2" | translate }}
      </div>
    </div>
  </div>
</div>
<div class="bottomarea d-print-none">
  <div class="blackbgpattern"></div>
</div>

<div class="bottomfasa" style="margin-top: 10rem"></div>
<div class="pagebreak"></div>
<div
  [ngStyle]="{ 'background-image': 'url(' + topfasa + ')' }"
  class="topfasa"
></div>

<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="nextSteps d-print-block">
      <div class="title">{{ "assessment.nextSteps" | translate }}</div>
      <div class="descr">{{ "assessment.stepsSub" | translate }}</div>
      <div class="points">
        <ul>
          <li>
            {{ "assessment.point1" | translate }}
          </li>
          <li>
            {{ "assessment.point2" | translate }}
          </li>
          <li>
            {{ "assessment.point3" | translate }}
          </li>
          <li>
            {{ "assessment.point4" | translate }}
          </li>
        </ul>
      </div>
    </div>

    <div class="byusing d-print-block">
      <p>
        {{ "registration.terms1" | translate }}
      </p>

      <p>
        {{ "registration.terms2" | translate }}
      </p>
    </div>

    <div class="copyright d-print-block">
      {{ "assessment.copyright1" | translate }}<br />
      {{ "assessment.copyright2" | translate }}
    </div>
  </div>
</div>

<div class="bottomfasa" style="margin-top: 10rem"></div>
