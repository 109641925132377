<div class="spinner-content">
  <div class="spinner-grow" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="loadingTxt">Calculating Data...</div>
  <div class="whatCalculates">
    <div class="essential" *ngIf="essential">Essential Infrastructure</div>
    <div class="organiz" *ngIf="organiz">Organizational Area</div>
    <div class="strategy" *ngIf="strategy">Strategy Area</div>
    <div class="abm" *ngIf="abm">ABM Area</div>
    <div class="performance" *ngIf="performance">Performance Area</div>
    <div class="oper" *ngIf="oper">Operational Area</div>
  </div>
</div>
