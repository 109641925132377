<div class="periexomeno">
  <div class="banner">
    <div class="stararea"></div>
    <div class="areas">
      <h1>Your Overall2</h1>
    </div>
    <div class="slogan">
      <h5>The Enzyme Β2Β Marketing Assessment Report</h5>
    </div>
    <div class="pattern"></div>
  </div>
</div>
<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="aboveBar">
      <h1 class="stage">Stage {{ stringScore }}</h1>
      <div class="icons">
        <div
          placement="top"
          ngbTooltip="Print page"
          class="printicon noPrint"
          (click)="printPage()"
        ></div>
      </div>
    </div>
    <div class="progressbaroverall">
      <ngb-progressbar
        type="primary"
        [value]="totalScore"
        [striped]="false"
      ></ngb-progressbar>
      <div class="totalscore">{{ totalScore | number : "1.2-2" }}</div>
    </div>
    <div class="summary">
      <b>{{ scoreTitle }}</b> - {{ scoreMessage }}
    </div>
    <!-- <p style="color: deeppink">
        Για την ώρα παίρνει standard νούμερα (της enzyme τα results)
      </p>
  
      <b>Total Score: {{ totalScore }}</b
      ><br /> -->

    <div class="twocols">
      <div class="chartarea">
        <div id="chart">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [labels]="chartOptions.labels"
            [fill]="chartOptions.fill"
            [stroke]="chartOptions.stroke"
            [responsive]="chartOptions.responsive"
            [legend]="chartOptions.legend"
            [colors]="chartOptions.colors"
          ></apx-chart>
        </div>
      </div>
      <div class="scorevalues noPrint">
        <h3>Score</h3>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[0]"
        >
          <div class="icon onep"></div>
          <div class="actualScore one">
            {{ firstScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[1]"
        >
          <div class="icon twop"></div>
          <div class="actualScore two">
            {{ secondScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[2]"
        >
          <div class="icon threep"></div>
          <div class="actualScore three">
            {{ thirdScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[3]"
        >
          <div class="icon fourp"></div>
          <div class="actualScore four">
            {{ forthScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[4]"
        >
          <div class="icon fivep"></div>
          <div class="actualScore five">
            {{ fifthScore }}
          </div>
        </div>
        <div
          class="sectionScore"
          placement="left"
          [ngbTooltip]="chartOptions.labels[5]"
        >
          <div class="icon sixp"></div>
          <div class="actualScore six">
            {{ sixthScore }}
          </div>
        </div>
      </div>
    </div>

    <div class="recommendations">
      <h1>Recomendations</h1>
      <p>
        Apart from your scoring performance, please review and consider the
        below recommendations on steps and initiatives to be taken for further
        improvements over each area.
      </p>
      <div class="rec">
        <div class="rect oneb"></div>
        <div class="rectxt">
          <div class="recTitle one">Essential Marketing Infrastructure</div>
          <div class="recDesc">
            {{ recommendation1 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect twob"></div>
        <div class="rectxt">
          <div class="recTitle two">Organizational Area</div>
          <div class="recDesc">
            {{ recommendation2 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect threeb"></div>
        <div class="rectxt">
          <div class="recTitle three">Metrics-Analytical Area</div>
          <div class="recDesc">
            {{ recommendation3 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect fourb"></div>
        <div class="rectxt">
          <div class="recTitle four">Customer Relationship Management</div>
          <div class="recDesc">
            {{ recommendation4 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect fiveb"></div>
        <div class="rectxt">
          <div class="recTitle five">Operational</div>
          <div class="recDesc">
            {{ recommendation5 }}
          </div>
        </div>
      </div>
      <div class="rec">
        <div class="rect sixb"></div>
        <div class="rectxt">
          <div class="recTitle six">Strategy Planning</div>
          <div class="recDesc">
            {{ recommendation6 }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="text-align: center">
        <div id="chartRadar">
          <apx-chart
            [series]="ChartOptionsRadar.series"
            [chart]="ChartOptionsRadar.chart"
            [xaxis]="ChartOptionsRadar.xaxis"
            [title]="ChartOptionsRadar.title"
          ></apx-chart>
        </div>
      </div> -->
    <!-- <div class="ranges">
        <h2 class="mb-3">Ranges</h2>
        <p style="color: deeppink">Απλά για test, σβήνουμε μετά</p>
        First score: <b>{{ firstScore }}</b>
        <input
          type="range"
          class="form-control-range"
          (change)="setCharts()"
          [(ngModel)]="firstScore"
        />
        Second score: <b>{{ secondScore }}</b>
        <input
          type="range"
          class="form-control-range"
          (change)="setCharts()"
          [(ngModel)]="secondScore"
        />
        Third score: <b>{{ thirdScore }}</b>
        <input
          type="range"
          class="form-control-range"
          (change)="setCharts()"
          [(ngModel)]="thirdScore"
        />
        Forth score: <b>{{ forthScore }}</b>
        <input
          type="range"
          class="form-control-range"
          (change)="setCharts()"
          [(ngModel)]="forthScore"
        />
        Fifth score: <b>{{ fifthScore }}</b>
        <input
          type="range"
          class="form-control-range"
          (change)="setCharts()"
          [(ngModel)]="fifthScore"
        />
        Sixth score: <b>{{ sixthScore }}</b>
        <input
          type="range"
          class="form-control-range"
          (change)="setCharts()"
          [(ngModel)]="sixthScore"
        />
      </div> -->

    <!-- <div class="replies">
        <h2 class="mt-3">Answers</h2>
        <div class="answers">
          <div class="replies">
            <div class="repl" *ngFor="let item of f1">
              1a - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of f2">
              1b - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of f3">
              1c - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of f4">
              1d - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of f5">
              1e - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of s1">
              2a - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of s2">
              2b - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of s3">
              2c - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of s4">
              2d - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of s5">
              2e - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of t1">
              3a - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of t2">
              3b - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of t3">
              3c - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of t4">
              3d - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fo1">
              4a - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fo2">
              4b - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fo3">
              4c - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fo4">
              4d - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fo5">
              4e - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fi1">
              5a - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fi2">
              5b - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fi3">
              5c - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of fi4">
              5d - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of si1">
              6a - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of si2">
              6b - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of si3">
              6c - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
          <div class="replies">
            <div class="repl" *ngFor="let item of si4">
              6d - <span class="badge badge-secondary">{{ item + 1 }}</span>
            </div>
          </div>
        </div>
      </div> -->
  </div>
</div>
<div class="bottomarea">
  <div class="blackbgpattern"></div>
</div>
