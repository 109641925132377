<div class="enzcontainerwhite">
  <div class="insideContainer">
    <div class="sectionTitle">
      <div class="titleTXT">{{ "third.title" | translate }}</div>
      <div class="titleIMG"></div>
    </div>

    <div class="twocols m-3" *ngIf="first">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "third.subtitle1" | translate }}</h2>
          <h5>{{ "third.subtitleBl1" | translate }}</h5>
          <div class="text">
            {{ questions[0] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="firstForm" novalidate>
          <div class="ratings">
            <div class="rating">
              <label>
                {{ firstData[0].name }}
              </label>
              <ngb-rating
                [(rate)]="range1a"
                [max]="1"
                [max]="5"
                [value]="range1a"
                name="range1a"
                id="inlineRadio1"
                [(ngModel)]="range1a"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
            <div class="rating">
              <label>
                {{ firstData[1].name }}
              </label>
              <ngb-rating
                [(rate)]="range1b"
                [max]="1"
                [max]="5"
                [value]="range1b"
                name="range1b"
                id="inlineRadio1"
                [(ngModel)]="range1b"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
            <div class="rating">
              <label>
                {{ firstData[2].name }}
              </label>
              <ngb-rating
                [(rate)]="range1c"
                [max]="1"
                [max]="5"
                [value]="range1c"
                name="range1c"
                id="inlineRadio1"
                [(ngModel)]="range1c"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
            <div class="rating">
              <label>
                {{ firstData[3].name }}
              </label>
              <ngb-rating
                [(rate)]="range1d"
                [max]="1"
                [max]="5"
                [value]="range1d"
                name="range1d"
                id="inlineRadio1"
                [(ngModel)]="range1d"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
          </div>
          <!-- <p
            class="error"
            *ngIf="this.firstForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 40;
                first = false;
                second = true;
                onSubmit(firstForm.value, 1)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="second">
      <div class="question">
        <div class="questionTXT">
          <h2>{{ "third.subtitle2" | translate }}</h2>
          <h5>{{ "third.subtitleBl2" | translate }}</h5>
          <div class="text">
            {{ questions[1] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="secondForm" novalidate>
          <div class="ratings">
            <div class="rating">
              <label> {{ secondData[0].name }} </label>
              <ngb-rating
                [(rate)]="range2a"
                [max]="1"
                [max]="5"
                [value]="range2a"
                name="range2a"
                id="inlineRadio1"
                [(ngModel)]="range2a"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
            <div class="rating">
              <label>
                {{ secondData[1].name }}
              </label>
              <ngb-rating
                [(rate)]="range2b"
                [max]="1"
                [max]="5"
                [value]="range2b"
                name="range2b"
                id="inlineRadio1"
                [(ngModel)]="range2b"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
            <div class="rating">
              <label>
                {{ secondData[2].name }}
              </label>
              <ngb-rating
                [(rate)]="range2c"
                [max]="1"
                [max]="5"
                [value]="range2c"
                name="range2c"
                id="inlineRadio1"
                [(ngModel)]="range2c"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
            <div class="rating">
              <label>
                {{ secondData[3].name }}
              </label>
              <ngb-rating
                [(rate)]="range2d"
                [max]="1"
                [max]="5"
                [value]="range2d"
                name="range2d"
                id="inlineRadio1"
                [(ngModel)]="range2d"
                [ngModelOptions]="{ standalone: true }"
              >
                <ng-template let-fill="fill" let-index="index">
                  <span class="star" [class.filled]="fill === 100"
                    >&#9679;</span
                  >
                </ng-template>
              </ngb-rating>
            </div>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.secondForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 60;
                third = true;
                second = false;
                onSubmit(secondForm.value, 2)
              "
            />
          </div>
        </form>
      </div>
    </div>
    <div class="twocols m-3" *ngIf="third">
      <div class="question">
        <div class="questionTXT">
          <h2 [innerHTML]="'third.subtitle3' | translate"></h2>
          <h5>{{ "third.subtitleBl3" | translate }}</h5>
          <div class="text">
            {{ questions[2] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="thirdForm" novalidate>
          <div class="inputlabel" *ngFor="let data of thirdData; let i = index">
            <input
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, thirdForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <div class="facebook" *ngIf="facebook">
            <div>
              <div class="question">
                FACEBOOK: Please select one: No likes yet, 1-50, 51-100,
                101-500, 500+ likes
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio1"
                  value="4"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >No likes yet</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="8"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">1-50</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="12"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >51-100</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="16"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >101-500</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq1"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="fbq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >500+ Likes</label
                >
              </div>
            </div>
            <div>
              <div class="question">
                FACEBOOK: Please select one: Very little or no posts, 1-3 posts
                per month, 3+ posts per month
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio1"
                  value="5"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Very little or no posts</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio2"
                  value="15"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >1-3 posts per month</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="fbq2"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="fbq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >3+ posts per month</label
                >
              </div>
            </div>
          </div>
          <div class="linkdin" *ngIf="linkdin">
            <div>
              <div class="question">
                LINKEDIN: Please select one: No connections yet, 1-50, 51-100,
                101-500, 500+ connection
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio1"
                  value="4"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >No connections yet</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="8"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2">1-50</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="12"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >51-100</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="16"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >101-500</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq1"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="linkq1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >500+ Connections</label
                >
              </div>
            </div>
            <div>
              <div class="question">
                LINKEDIN: Please select one: Very little or no posts, 1-3 posts
                per month, 3+ posts per month
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio1"
                  value="5"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >Very little or no posts</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio2"
                  value="15"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >1-3 posts per month</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="linkq2"
                  id="inlineRadio2"
                  value="20"
                  [(ngModel)]="linkq2"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >3+ posts per month</label
                >
              </div>
            </div>
          </div>
          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 80;
                forth = true;
                third = false;
                onSubmit(thirdForm.value, 3)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="forth">
      <div class="question">
        <div class="questionTXT">
          <h2 [innerHTML]="'third.subtitle4' | translate"></h2>
          <h5>{{ "third.subtitleBl4" | translate }}</h5>
          <div class="text">
            {{ questions[3] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
          <div class="square"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="forthForm" novalidate>
          <div class="inputlabel" *ngFor="let data of forthData; let i = index">
            <input
              type="radio"
              [value]="data.value"
              (change)="onRadioChange($event, forthForm, i)"
            />
            <label>
              {{ data.name }}
            </label>
          </div>

          <!-- <p
            class="error"
            *ngIf="this.forthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->

          <div class="btnSubmit">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                fifth = true;
                forth = false;
                onSubmit(forthForm.value, 4)
              "
            />
          </div>
        </form>
      </div>
    </div>

    <div class="twocols m-3" *ngIf="fifth">
      <div class="empty" *ngIf="showNext"></div>
      <div class="question" *ngIf="!showNext">
        <div class="questionTXT">
          <h2>{{ "third.subtitle5" | translate }}</h2>
          <h5>{{ "third.subtitleBl5" | translate }}</h5>
          <div class="text">
            {{ questions[4] }}
          </div>
        </div>
        <div class="sectionProgress">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square active"></div>
        </div>
      </div>
      <div class="answers">
        <form [formGroup]="fifthForm" novalidate>
          <div class="if" *ngIf="!showNext">
            <div class="ratings">
              <div class="rating">
                <label> {{ fifthData[0].name }} </label>
                <ngb-rating
                  [(rate)]="range5a"
                  [max]="1"
                  [max]="5"
                  [value]="range5a"
                  name="range5a"
                  id="inlineRadio1"
                  [(ngModel)]="range5a"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100"
                      >&#9679;</span
                    >
                  </ng-template>
                </ngb-rating>
              </div>
              <div class="rating">
                <label> {{ fifthData[1].name }}</label>
                <ngb-rating
                  [(rate)]="range5b"
                  [max]="1"
                  [max]="5"
                  [value]="range5b"
                  name="range5b"
                  id="inlineRadio1"
                  [(ngModel)]="range5b"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100"
                      >&#9679;</span
                    >
                  </ng-template>
                </ngb-rating>
              </div>
              <div class="rating">
                <label> {{ fifthData[2].name }} </label>
                <ngb-rating
                  [(rate)]="range5c"
                  [max]="1"
                  [max]="5"
                  [value]="range5c"
                  name="range5c"
                  id="inlineRadio1"
                  [(ngModel)]="range5c"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100"
                      >&#9679;</span
                    >
                  </ng-template>
                </ngb-rating>
              </div>
              <div class="rating">
                <label> {{ fifthData[3].name }} </label>
                <ngb-rating
                  [(rate)]="range5d"
                  [max]="1"
                  [max]="5"
                  [value]="range5d"
                  name="range5d"
                  id="inlineRadio1"
                  [(ngModel)]="range5d"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100"
                      >&#9679;</span
                    >
                  </ng-template>
                </ngb-rating>
              </div>
              <div class="rating">
                <label> {{ fifthData[4].name }} </label>
                <ngb-rating
                  [(rate)]="range5e"
                  [max]="1"
                  [max]="5"
                  [value]="range5e"
                  name="range5e"
                  id="inlineRadio1"
                  [(ngModel)]="range5e"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100"
                      >&#9679;</span
                    >
                  </ng-template>
                </ngb-rating>
              </div>
            </div>
          </div>
          <!-- 
          <p
            class="error"
            *ngIf="this.fifthForm.controls['checkArray'].errors?.required"
          >
            Checkbox is required, select atleast one value.
          </p> -->
          <div class="showNext" *ngIf="showNext">
            <p [innerHTML]="'third.nextAreaTxt' | translate"></p>
            <div>
              <span [innerHTML]="'third.letsrate' | translate"></span>
              <span class="highlight">{{
                "third.nextAreaTitle" | translate
              }}</span
              >.
            </div>
          </div>
          <div class="btnSubmit" *ngIf="!showNext">
            <input
              type="submit"
              value="{{ nextTxt }}"
              (click)="
                progress = 100;
                disabled = false;
                showNext = true;
                onSubmit(fifthForm.value, 5)
              "
            />
          </div>
          <div class="btnNextSection" *ngIf="showNext">
            <input
              type="submit"
              value="{{ nextArea }}"
              (click)="goToNextArea()"
            />
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="totalScore">
      Total Score for first Section: {{ totalScore }}
    </div>
    <button
      [disabled]="disabled"
      type="button"
      class="btn btn-primary mt-3"
      routerLink="/second"
    >
      Next section
    </button> -->
  </div>
</div>
<div class="enzcontainer">
  <div class="progressbar">
    <ngb-progressbar
      type="primary"
      [value]="progress"
      [striped]="false"
    ></ngb-progressbar>
  </div>
</div>

<!-- <div class="enzcontainer">
  <div class="deleteLater">
    <h2>Ignore, we will delete later</h2>
    <div class="scores">
      <span class="score">{{ firstScore }}</span>
      <span class="score">{{ secondScore }}</span>
      <span class="score">{{ thirdScore }}</span>
      <span class="score">{{ forthScore }}</span>
      <span class="score">{{ fitfthScore }}</span>
    </div>
  </div>
</div> -->

<!-- <h2 class="mb-3">Third Section</h2>
<p>
  <ngb-progressbar
    type="primary"
    [value]="progress"
    [striped]="true"
  ></ngb-progressbar>
</p>
<div class="scores">
  <span class="score">{{ firstScore }}</span>
  <span class="score">{{ secondScore }}</span>
  <span class="score">{{ thirdScore }}</span>
  <span class="score">{{ forthScore }}</span>
</div>
<div class="card m-3" *ngIf="first">
  <h5 class="card-header">
    1. Marketing Analytics. Explore how your marketing analytics is organized.
    Please select the statement that best describes your company:
  </h5>
  <div class="card-body">
    <form [formGroup]="firstForm" novalidate>
      <div *ngFor="let data of firstData; let i = index">
        <label>
          <input
            type="radio"
            name="firstRadios"
            [value]="data.value"
            (change)="onRadioChange($event, firstForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.firstForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 25;
          first = false;
          second = true;
          onSubmit(firstForm.value, 1)
        "
      />
    </form>
  </div>
</div>
<div class="card m-3" *ngIf="second">
  <h5 class="card-header">
    2. Metrics & Benchmarking Definition. Explore your company’s marketing and
    customer experience metrics set-up and decision support. Please select all
    that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="secondForm" novalidate>
      <div *ngFor="let data of secondData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, secondForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.secondForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 50;
          third = true;
          second = false;
          onSubmit(secondForm.value, 2)
        "
      />
    </form>
  </div>
</div>
<div class="card m-3" *ngIf="third">
  <h5 class="card-header">
    3. Performance Analytics. Evaluate how well you have defined your marketing
    objectives and identified how success will be measured. Please select the
    statement that best describes your company:
  </h5>
  <div class="card-body">
    <form [formGroup]="thirdForm" novalidate>
      <div *ngFor="let data of thirdData; let i = index">
        <label>
          <input
            type="radio"
            name="thirdRadios"
            [value]="data.value"
            (change)="onRadioChange($event, thirdForm, i); showFBLinkedin(i)"
          />
          {{ data.name }}
        </label>
      </div>

      <input
        type="submit"
        value="Submit"
        (click)="
          progress = 75;
          forth = true;
          third = false;
          onSubmit(thirdForm.value, 3)
        "
      />
    </form>
  </div>
</div>

<div class="card m-3" *ngIf="forth">
  <h5 class="card-header">
    4. Martech capabilities. Audit the availability and use of your marketing
    technology to refine its capabilities and prove its ROI. Please select all
    that apply.
  </h5>
  <div class="card-body">
    <form [formGroup]="forthForm" novalidate>
      <div *ngFor="let data of forthData; let i = index">
        <label>
          <input
            type="checkbox"
            [value]="data.value"
            (change)="onCheckboxChange($event, forthForm, i)"
          />
          {{ data.name }}
        </label>
      </div>

      <p
        class="error"
        *ngIf="this.forthForm.controls['checkArray'].errors?.required"
      >
        Checkbox is required, select atleast one value.
      </p>

      <input
        type="submit"
        value="Submit"
        (click)="progress = 100; disabled = false; onSubmit(forthForm.value, 4)"
      />
    </form>
  </div>
</div>

<div class="totalScore">Total Score for first Section: {{ totalScore }}</div>
<button
  [disabled]="disabled"
  type="button"
  class="btn btn-primary mt-3"
  routerLink="/forth"
>
  Next section
</button> -->
