import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FiredataService {
  submissions: Observable<any[]>;
  backendSubmissions: Observable<any[]>;
  sharelinks: Observable<any[]>;
  qDate = moment().subtract(30, 'days').format('DD/M/YYYY, hh:mm:ss μ.μ.');

  private subsCollection: AngularFirestoreCollection<any>;
  private backendSubsCollection: AngularFirestoreCollection<any>;
  private linksCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.subsCollection = this.afs.collection<any>('submissions');
    this.backendSubsCollection = this.afs.collection<any>('submissions');
    this.linksCollection = this.afs.collection<any>('links');

    this.backendSubmissions = this.backendSubsCollection.valueChanges();
    this.submissions = this.subsCollection.valueChanges();
    this.sharelinks = this.linksCollection.valueChanges();
  } // /costr

  addSubscription(item: any) {
    this.subsCollection.add(item);
  }
  // / addSubscription

  createLink(data: any, id: string) {
    console.log(data);
    const col = this.afs.collection<any>('links', (ref) =>
      ref.where('id', '==', id)
    );
    const sub = col.valueChanges().subscribe((res) => {
      console.log(res);
      if (res.length > 0) {
        this.linksCollection.doc(id).update(data);
        console.log('updated');
      } else {
        this.linksCollection.doc(id).set(data);
        console.log('created');
      }
    });
    setTimeout(() => {
      sub.unsubscribe();
    }, 1000);
  }
  // / createLink

  getSubmissions() {
    return this.backendSubmissions;
  }

  setDate(q) {
    const tD = q.month + '/' + q.day + '/' + q.year;
    const nD = new Date(tD);

    this.qDate = moment(nD).format('DD/M/YYYY, hh:mm:ss μ.μ.');

    console.log(this.qDate);
    this.backendSubsCollection = this.afs.collection<any>(
      'submissions',
      (ref) => ref.where('datelocale', '<', this.qDate)
    );
    this.backendSubmissions = this.backendSubsCollection.valueChanges();
  }
}
// / class
