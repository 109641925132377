import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { LocalService } from 'src/app/services/local.service';
import { LocalServiceGR } from 'src/app/services/localgr.service';
import { LocalServiceFR } from 'src/app/services/localfr.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-fifth',
  templateUrl: './fifth.component.html',
  styleUrls: ['./fifth.component.scss'],
})
export class FifthComponent implements OnInit {
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  forthForm: FormGroup;
  fifthForm: FormGroup;
  facebook = false;
  linkdin = false;
  date: any;
  score = 0;
  firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fitfthScore = 0;
  totalScore = 0;
  first = true;
  second = false;
  third = false;
  forth = false;
  fifth = false;
  showNext = false;
  progress = 0;
  disabled = true;
  firstData: Array<any> = [];

  secondData: Array<any> = [];

  thirdData: Array<any> = [];

  forthData: Array<any> = [];
  fifthData: Array<any> = [];
  fbq1 = 0;
  fbq2 = 0;
  linkq1 = 0;
  linkq2 = 0;
  questions: any = [];
  localData: any;
  currentLanguage: string;
  subscription: Subscription;
  nextTxt = 'NEXT';
  nextArea = 'NEXT AREA';
  constructor(
    private fb: FormBuilder,

    private localDataen: LocalService,
    private localDatagr: LocalServiceGR,
    private localDatafr: LocalServiceFR,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.date = new Date();

    this.localData = this.localDataen;
    this.setQuestions();
  }
  // / constr

  setQuestions() {
    if (this.currentLanguage === 'en') {
      this.localData = this.localDataen;
      this.nextTxt = 'NEXT';
      this.nextArea = 'NEXT AREA';
    }
    if (this.currentLanguage === 'el') {
      this.localData = this.localDatagr;
      this.nextTxt = 'ΕΠΟΜΕΝΗ';
      this.nextArea = 'ΕΠΟΜΕΝΟΣ ΤΟΜΕΑΣ';
    }
    if (this.currentLanguage === 'fr') {
      this.localData = this.localDatafr;
      this.nextTxt = 'SUIVANTE';
      this.nextArea = 'ZONE SUIVANTE';
    }
    this.firstData = this.localData.fifthSection[0].questions;
    this.secondData = this.localData.fifthSection[1].questions;
    this.thirdData = this.localData.fifthSection[2].questions;
    this.forthData = this.localData.fifthSection[3].questions;
    // this.fifthData = this.localData.fifthSection[4].questions;
    this.questions = [];

    this.questions.push(this.localData.fifthSection[0].qtxt);
    this.questions.push(this.localData.fifthSection[1].qtxt);
    this.questions.push(this.localData.fifthSection[2].qtxt);
    this.questions.push(this.localData.fifthSection[3].qtxt);
    // this.questions.push(this.localData.fifthSection[4].qtxt);
    // console.log(this.questions);

    this.firstForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
    this.secondForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
    this.thirdForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });

    this.forthForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
    this.fifthForm = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      answersArr: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.subscription = this.languageService.currentLanguage$.subscribe(
      (language) => {
        this.currentLanguage = language;
        this.setQuestions();
        console.log(this.currentLanguage);
      }
    );
  }
  // / init

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  showFBLinkedin(i) {
    if (i === 0) {
      this.facebook = !this.facebook;
    }
    if (i === 1) {
      this.linkdin = !this.linkdin;
    }
  }
  // / showFBLinkedin

  onCheckboxChange(e, form, num) {
    const checkArray: FormArray = form.get('checkArray') as FormArray;
    const answersArr: FormArray = form.get('answersArr') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      answersArr.push(new FormControl(num));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
      answersArr.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          answersArr.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  // /onCheckboxChange

  onRadioChange(e, form, num) {
    const checkArray: FormArray = form.get('checkArray') as FormArray;
    const answersArr: FormArray = form.get('answersArr') as FormArray;

    if (e.target.checked) {
      checkArray.removeAt(0);
      checkArray.push(new FormControl(e.target.value));
      answersArr.removeAt(0);
      answersArr.push(new FormControl(num));
    }
  }
  // /onRadioChange

  onSubmit(form, i) {
    this.totalScore = 0;
    const formaValue = form;

    // formaValue.date = this.date;
    this.score = 0;
    formaValue.checkArray.forEach((element) => {
      this.score = this.score + Number(element);
    });
    if (i === 3) {
      if (this.facebook) {
        this.score = this.score + Number(this.fbq1);
        this.score = this.score + Number(this.fbq2);
      }
      if (this.linkdin) {
        this.score = this.score + Number(this.linkq1);
        this.score = this.score + Number(this.linkq2);
      }
    }
    console.log(formaValue.answersArr);

    switch (i) {
      case 1:
        this.firstScore = this.score;
        this.localData.fi1 = formaValue.answersArr;
        break;
      case 2:
        this.secondScore = this.score;
        this.localData.fi2 = formaValue.answersArr;
        break;
      case 3:
        this.thirdScore = this.score;
        this.localData.fi3 = formaValue.answersArr;
        break;
      case 4:
        this.forthScore = this.score;
        this.localData.fi4 = formaValue.answersArr;
        break;
      // case 5:
      //   this.fitfthScore = this.score;
      //   break;

      default:
        break;
    }
    this.totalScore =
      (this.firstScore + this.secondScore + this.thirdScore + this.forthScore) /
      4;

    this.localData.setFifthScore(this.totalScore);
    console.log(formaValue);
    // this.loadSpinner(formaValue);
  }
  // / submit
  goToNextArea() {
    console.log('go to next area');
    this.router.navigate(['/sixth']);
  }
}
// / class
