import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToolbarComponent } from './shared/toolbar/toolbar.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { IntroComponent } from './pages/home/intro/intro.component';
import { RememberthinkComponent } from './pages/home/rememberthink/rememberthink.component';
import { SixareasComponent } from './pages/home/sixareas/sixareas.component';
import { UnderstandscoreComponent } from './pages/learnmore/understandscore/understandscore.component';
import { SixsectionsComponent } from './pages/learnmore/sixsections/sixsections.component';
import { FourstepsComponent } from './pages/learnmore/foursteps/foursteps.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ConfirmComponent } from './modals/confirm/confirm.component';
import { SharedurlComponent } from './modals/sharedurl/sharedurl.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    routingComponents,
    IntroComponent,
    RememberthinkComponent,
    SixareasComponent,
    UnderstandscoreComponent,
    SixsectionsComponent,
    FourstepsComponent,
    SpinnerComponent,
    ConfirmComponent,
    SharedurlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
