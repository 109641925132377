<div class="twocols">
  <div class="left">
    <div class="item one">
      <h4>{{ "learnmore.essential" | translate }}</h4>
      <p>
        {{ "learnmore.essentialTXT" | translate }}
      </p>
    </div>
    <div class="item two">
      <h4>{{ "learnmore.marketing" | translate }}</h4>
      <p>
        {{ "learnmore.marketingTXT" | translate }}
      </p>
    </div>
    <div class="item three">
      <h4>{{ "learnmore.performance" | translate }}</h4>
      <p>
        {{ "learnmore.performanceTXT" | translate }}
      </p>
    </div>
  </div>
  <div class="right">
    <div class="item four">
      <h4>{{ "learnmore.ogranizational" | translate }}</h4>
      <p>
        {{ "learnmore.ogranizationalTXT" | translate }}
      </p>
    </div>
    <div class="item five">
      <h4>{{ "learnmore.account" | translate }}</h4>
      <p>
        {{ "learnmore.accountTXT" | translate }}
      </p>
    </div>
    <div class="item six">
      <h4>{{ "learnmore.operational" | translate }}</h4>
      <p>
        {{ "learnmore.operationalTXT" | translate }}
      </p>
    </div>
  </div>
</div>
