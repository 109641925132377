<!-- Toolbar -->
<div class="toolbar noPrint" role="banner">
  <span class="logo" routerLink="/">
    <img src="./assets/enzyme_b2b_navigator_logo_Final.png" alt="" />
  </span>
  <div class="spacer">
    <div class="language-selector" style="display: flex">
      <div
        *ngFor="let language of languages"
        class="flag"
        (click)="changeLanguage(language.code)"
      >
        <img
          [src]="language.flag"
          [alt]="language.code"
          [class.hidden]="isSelected(language.code)"
        />
      </div>
    </div>
  </div>
  <span>
    <a class="hidemobile" href="https://enzyme.gr" target="_blank">
      <div class="getMore noPrint">{{ "toolbar.getmore" | translate }}</div>
    </a>
  </span>
</div>
