import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  firstSection: any;
  secondSection: any;
  thirdSection: any;
  forthSection: any;
  fifthSection: any;
  sixthSection: any;
  company = '';
  public firstScore = 0;
  secondScore = 0;
  thirdScore = 0;
  forthScore = 0;
  fifthScore = 0;
  sixthScore = 0;

  rec1: any;
  rec2: any;
  rec3: any;
  rec4: any;
  rec5: any;
  rec6: any;
  fbAnswers = [];
  linkdinAnswrs = [];
  f1 = [];
  f2 = [];
  f3 = [];
  f4 = [];
  f5 = [];
  s1 = [];
  s2 = [];
  s3 = [];
  s4 = [];
  s5 = [];
  t1 = [];
  t2 = [];
  t3 = [];
  t4 = [];
  t5 = [];
  fo1 = [];
  fo2 = [];
  fo3 = [];
  fo4 = [];
  fo5 = [];
  fi1 = [];
  fi2 = [];
  fi3 = [];
  fi4 = [];
  si1 = [];
  si2 = [];
  si3 = [];
  si4 = [];
  constructor() {
    this.firstSection = [
      {
        qtxt: "Please provide your company's website information. How well are the products/solutions/services and the relevant content offerings organized on your website? Does your website support your sales team now with full information and interactive, interesting content? Please select all that apply.",
        q: '1a',
        questions: [
          { name: 'My company has a website.', value: 20 },
          {
            name: "My company's website represents a culture of quality messaging, depth of content, rich graphics, simplicity of navigation, presentation etc. There are basic navigation links for product/solutions/ services offerings.",
            value: 20,
          },
          {
            name: 'There is a  variety of technical and business content offers (like ebooks, webinars, articles,infographics, case studies, white papers, how-to videos etc.) on my company’s website.',
            value: 20,
          },
          {
            name: 'Our company website contains a dynamic array of resources, advice and guidance related to our services, along with information on our key partnerships with vendors. It also features an email subscription form for our newsletters.',
            value: 20,
          },
          {
            name: 'My company’s website has a blog and is regularly updated with new content and information  related to our offerings. Almost every page has a call to action. ',
            value: 20,
          },
          { name: 'None of the above.', value: 0 },
        ],
      },
      {
        qtxt: "How important is your company's offline presence? Does your company use events & conferences for engagement, networking and extroversion? Please select the statement that best describes your company:",
        q: '1b',
        questions: [
          {
            name: 'Events organizing, participation in Tradeshows and third-party conferences are included in the annual planning.',
            value: 100,
          },
          {
            name: 'My company maintains an adequate presence and extrovert policy that can be considered as a good fit for the industry (Organizing at least 3 events per year and participating in 2 Tradeshows, or third-party conferences.)',
            value: 75,
          },
          {
            name: 'My company occasionally organizes some events and one-to-one meetings with clients and prospects and has a significant presence in third-party conferences.',
            value: 50,
          },
          {
            name: 'My company rarely organizes events and has a light presence in some conferences, but no specific strategy or goals around how we should be using it.',
            value: 25,
          },
          { name: 'None of the above.', value: 0 },
        ],
      },
      {
        qtxt: "Please provide information on your company's social media pages, and then specify the level of its social media activity by citing the popularity and posting frequency across your LinkedIn and Facebook accounts.",
        q: '1c',
        questions: [
          { name: 'Does your company have a Facebook page?', value: 10 },
          { name: 'Does your company have a LinkedIn account?', value: 10 },
        ],
      },
      {
        qtxt: 'Please provide the processes or website functionalities that are in place or  active to generate leads and traffic. Which of the following are present on your company website? Please select all that apply.',
        q: '1d',
        questions: [
          {
            name: 'A telephone number or live chat options (Messenger/ Skype / Whatsapp/ Viber or else) are available to customers to facilitate contacts and inquiries. ',
            value: 10,
          },
          { name: 'The company has Google My Business account.', value: 10 },
          {
            name: "The company's website has an online contact form. ",
            value: 10,
          },
          {
            name: 'Responds to leads from events, searches, referrals, gated content and other relevant engagements have been put in place.',
            value: 10,
          },
          { name: "The company's website is mobile-optimized.", value: 10 },
          {
            name: "There is an Email/Newsletter platform to generate content and inform on clients' responses to offers.",
            value: 10,
          },
          {
            name: 'Website content such as articles, blog texts, videos etc is shared on social media platforms- Facebook, LinkedIn or X.',
            value: 10,
          },
          {
            name: 'Online registration after confirming interest for a demo, or trials, helps in prospect client acquisition and purchases.',
            value: 10,
          },
          {
            name: 'There is enabled online ordering and purchasing.',
            value: 20,
          },
          { name: 'None of the above.', value: 0 },
        ],
      },
      {
        qtxt: 'Please assess the use of marketing-advertising techniques  such as SEO, PPC, Video and email. Please select all that apply.',
        q: '1e',
        questions: [
          {
            name: 'My company has a Google AdWords account and actively implements PPC, Keywords or Display campaigns.',
            value: 20,
          },
          { name: 'My company has a YouTube channel.', value: 20 },
          {
            name: 'My company has Email platform, an active list of recipients and sends out an email at least once a month. ',
            value: 20,
          },
          {
            name: 'My company creates video content and publishes at least 6 times a year.',
            value: 20,
          },
          { name: 'SEO is a strategic approach for my company.', value: 20 },
          { name: 'None of the above.', value: 0 },
        ],
      },
    ];
    this.secondSection = [
      {
        qtxt: "Assess the level of your company's marketing strategy, planning and implementation. Please select the statement that best describes your company:",
        q: '2a',
        questions: [
          {
            name: 'Marketing increases revenues and prospective effectiveness for my company.',
            value: 100,
          },
          {
            name: 'Marketing helps in generating leads for the company.',
            value: 75,
          },
          {
            name: 'A range of marketing activities are executed, often customized for digital content.',
            value: 50,
          },
          {
            name: 'A marketing strategy and visionary planning are implemented.',
            value: 25,
          },
          {
            name: 'There is no specific marketing strategy.',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Assess the structure and availability of marketing resources within your company.  Do you currently have dedicated marketing employees or an agency partner?  Please select the statement that best describes your company:',
        q: '2b',
        questions: [
          {
            name: 'My company has no marketing resources.',
            value: 0,
          },
          {
            name: 'My company has part-time employees or limited marketing resources or these resources are shared with other functions.',
            value: 30,
          },
          {
            name: 'My company has one person (or small team) as dedicated marketing resources.',
            value: 70,
          },
          {
            name: 'My company has dedicated marketing resources (more than one person) and an agency partner. ',
            value: 100,
          },
        ],
      },
      {
        qtxt: "How would you describe your company's commitment level to marketing and especially digital marketing. Evaluate the support from top management. Please select the statement that best describes your company:",
        q: '2c',
        questions: [
          {
            name: 'Not at all Committed. There is no support from Top management.',
            value: 0,
          },
          {
            name: 'Not Very Committed.  Management provides verbal support only and insufficient support and resourcing.',
            value: 25,
          },
          {
            name: 'Somewhat Committed. There is a Top management medium support and a plan in place.',
            value: 50,
          },
          {
            name: 'Very Committed. Top management provides sponsorship and a moderate investment roadmap.',
            value: 75,
          },
          {
            name: 'Extremely Committed. Outstanding top management support and budget commitment.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Please assess the availability and use of your marketing plan and other resources. Please select all that apply.',
        q: '2d',
        questions: [
          {
            name: 'My company has no marketing plan in place but implements  some marketing activities from time to time.',
            value: 10,
          },
          {
            name: 'My company has an initial marketing plan in place but there is no implementation yet.',
            value: 10,
          },
          {
            name: 'The marketing department supports my sales team with full information and interactive, interesting content.',
            value: 20,
          },
          {
            name: 'Knowledge of  industry and competitors. There is a competitive analysis of my company vs. your competitors in their marketing and lead generation.',
            value: 20,
          },
          {
            name: 'The marketing department has enough marketing tools (software) and outsourced resources to implement campaigns.',
            value: 20,
          },
          {
            name: 'There is a specific budget to invest in marketing activities for growth.',
            value: 20,
          },
          {
            name: 'None of the above.',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Assess the marketing tools and systems in place within your company to leverage (prospect) client engagement. Please select the phrase best describes your company:',
        q: '2e',
        questions: [
          {
            name: 'Marketing focused on relationships with direct (face to face) engagement and networking through events. Low level of digital and social media marketing initiatives.',
            value: 25,
          },
          {
            name: 'Building relationships mainly through customer engagement initiatives such as events and traditional media. Increasingly adaptive to digital communication platforms and channels.',
            value: 50,
          },
          {
            name: 'Customary and digital tools are equally leveraged to engage prospect clients and empower new business models.',
            value: 75,
          },
          {
            name: 'Digital marketing assets such as emails or landing pages, and social media communication are the essential tools to empower new relationships and business models. ',
            value: 100,
          },
        ],
      },
    ];
    this.thirdSection = [
      {
        qtxt: 'Rate your customer value proposition vs your competitors.',
        q: '3a',
        questions: [
          {
            name: 'My company has a well-defined customer value proposition (CVP)',
            value: 25,
          },
          {
            name: 'Our customer value proposition is Desirable (does it meet what customers need?)',
            value: 25,
          },
          {
            name: 'Our customer value proposition is Distinctive (in what way does it stand out from other companies’ CVPs?) ',
            value: 25,
          },
          {
            name: 'Our customer value proposition is Defensible (what “proof points” have we got that it will do what we say?)',
            value: 25,
          },
        ],
      },
      {
        qtxt: 'Rate your positioning and its strength level of your marketing vs your competitors.',
        q: '3b',
        questions: [
          {
            name: 'My company has a well-defined positioning.',
            value: 25,
          },
          {
            name: 'My company/ brand is different and distinctive vs our three basic competitors (differentiation is about maximizing the perceived difference between us and competitors.)',
            value: 25,
          },
          {
            name: 'Our positioning  is associated with core values (for the industry) and has a clear messaging strategy.',
            value: 25,
          },
          {
            name: "Our well-defined customer value proposition has the relevance factor.  (Relevance is about maximizing the perceived fit between us and our customers' needs.)",
            value: 25,
          },
        ],
      },
      {
        qtxt: 'Assess how you work and rate your company marketing content and its effectiveness. Please select the statement that best describes your company:',
        q: '3c',
        questions: [
          {
            name: 'Minimally Effective. Content is created rarely or at ad hoc basis. We use content from our vendor partners (eg. We have access to their marketing content synchronization tools.)',
            value: 25,
          },
          {
            name: 'Moderately Effective. Basic content creation such as eNewsletters, posts, blogs, case studies, webinars) produced mostly for campaigns and tactical activities (and third-party content of partner/vendors if applied.)',
            value: 50,
          },
          {
            name: "Effective. A repository of company's new created content plus third-party content of partner/vendors is in place and used to drive growth. ",
            value: 75,
          },
          {
            name: 'Extremely Effective. Original engaging content is created and mapped by customer journey stage and persona [for example, defining content goals (thought leadership, build consensus, confirm value etc) using key metrics to evaluate (eg. traffic, shares, downloads, conversions, revenue attribution.)',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Assess  your customer journey mapping in regards how it aligns to your content  and its effectiveness to drive sales. Please select the statement that best describes your company:',
        q: '3d',
        questions: [
          {
            name: 'There is no Customer Journey recorded.  ',
            value: 25,
          },
          {
            name: 'A Customer Journey is in place with basic phases identified (without having the acceptance of the sales team.)',
            value: 50,
          },
          {
            name: 'A well-defined Customer Journey  with the mapping of content being leveraged by both marketing & sales team. ',
            value: 75,
          },
          {
            name: 'A fully structured Customer Journey  mapped with the appropriate content at each stage driving sales to our company. ',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'How would you rate the strength of your strategy in each of these areas:',
        q: '3e',
        questions: [
          {
            name: 'Marketing Automation / MarTech capabilities',
            value: 20,
          },
          {
            name: 'Digital Advertising ',
            value: 20,
          },
          {
            name: 'Direct  & Email Marketing',
            value: 20,
          },
          {
            name: 'Event Marketing',
            value: 20,
          },
          {
            name: 'Social Media Marketing',
            value: 20,
          },
        ],
      },
    ];
    this.forthSection = [
      {
        qtxt: 'Evaluate the value of your  marketing database which can drive segmentation and performance. Please select the statement that best describes your company:',
        q: '4a',
        questions: [
          {
            name: 'We use a limited marketing database (offline list from old emails etc)',
            value: 25,
          },
          {
            name: 'A centralized (of low size and quality) database has been put into place with basic segments identified.',
            value: 50,
          },
          {
            name: 'A segmented database (of medium size and quality), able to locate potential targets for up-selling / cross-selling has been put into place.',
            value: 75,
          },
          {
            name: 'A high quality advanced segmented database has been put into place based on firmographics (B2B demographics), Behavioral, Needs and historical data to support end-to-end  customer journeys.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Evaluate the capabilities of your Customer Relationship Management platform. Please select the phrase that best describes your company:',
        q: '4b',
        questions: [
          {
            name: 'Sales contacts, pipeline and funnel are carried out through various applications, without the presence of a central CRM platform.',
            value: 25,
          },
          {
            name: 'Use of basically customized metric and analytics processes, while implementing a contact database.',
            value: 50,
          },
          {
            name: 'Our CRM platform has been organised with clearly marked processes and tracked performance indicators.',
            value: 75,
          },
          {
            name: "Our CRM platform has been organised, built into customer experience systems. The company's Customer Relationship Management systems work well providing regular updates of customer data.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Evaluate your lead management for qualification and scoring. Please select the statement that best describes your company:',
        q: '4c',
        questions: [
          {
            name: 'Limited.There are no defined criteria established to qualify leads',
            value: 25,
          },
          {
            name: 'Sufficient with lead stage definitions & targets. Lead nurturing and lead qualification processes are defined, though not widely used.',
            value: 50,
          },
          {
            name: 'Good. Leads are generated, qualified and counted throughout the sales process. Metrics such as Average deal size at opportunity creation vs. opportunity closure, CLV, COCA are available.',
            value: 75,
          },
          {
            name: 'Excellent. Leads are counted and measured within marketing automation software, enhanced with data from other digital & social channels and events, webinars etc. ',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Evaluate your pipeline management across sales and marketing. Please select the phrase that best describes your company:',
        q: '4d',
        questions: [
          {
            name: 'Limited Pipeline Progression. Marketing and sales pipelines are handled with customized tools, without the use of a CRM platform.',
            value: 25,
          },
          {
            name: 'Average. Pipeline reports are based on the CRM database, without great accuracy because of the limited quality of the data and/or a lack of clearly defined metrics.',
            value: 50,
          },
          {
            name: 'Sufficient. Pipeline reports include high quality data, even though there are no ongoing updates and monitoring of KPIs is run on a customized basis.',
            value: 75,
          },
          {
            name: 'Superior. Pipeline reporting is automatically updated, and adequately defined throughout the sales process, driving team activities for optimal business results.',
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Evaluate and rate how closely aligned are sales and marketing teams on what constitutes a qualified lead and their alignment for drive growth. Please select the statement that best describes your company:',
        q: '4e',
        questions: [
          {
            name: 'Misalignment. The company naturally understands the characteristics of a qualified lead, but there are Sales and Marketing teams conflicts and the coordination-cooperation between them is very low.',
            value: 25,
          },
          {
            name: 'Low  alignment. Sales people often complain that their leads are not good enough. Marketers don’t know which assets are most useful for their sales colleagues.  Additional effort required to benefit from sales-marketing alignment.',
            value: 50,
          },
          {
            name: '"Somewhat" alignment. Both Teams try to align as indicated by the growth rate and revenue increases.',
            value: 75,
          },
          {
            name: 'Complete alignment. Both Teams have shared goals and a written document that outlines what an MQL is. Teams are fully aligned, and the company earns higher order value from nurtured leads.',
            value: 100,
          },
        ],
      },
    ];
    this.fifthSection = [
      {
        qtxt: 'Explore how your marketing analytics is organised. Please select the statement that best describes your company:',
        q: '5a',
        questions: [
          {
            name: 'There is limited use of analytics across my company (eg standard-level email reporting or web data collecting).',
            value: 25,
          },
          {
            name: 'An external vendor provides customized analytics support on a regular basis.',
            value: 50,
          },
          {
            name: "Analytics data from comparable activities (such as a series of past campaigns) are processed to assess the efficiency of marketing, without determining  the company's business strategy.",
            value: 75,
          },
          {
            name: "Analytics have a deep impact on the company's planning and decision making across all marketing channels.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Explore your company’s marketing and customer experience metrics set-up. Please select all that apply.',
        q: '5b',
        questions: [
          {
            name: 'Customer loyalty metrics such as NPS (Net Promoter Score), customer satisfaction, likelihood to purchase, customer effort etc.',
            value: 25,
          },
          {
            name: 'Quality metrics such as product failure rates, product length of life, cost of maintenance etc.',
            value: 25,
          },
          {
            name: 'Campaigns Costs and effectiveness metrics such as cost rates, cost per lead, impressions vs industry benchmarks, cost of new customer acquisition etc.',
            value: 25,
          },
          {
            name: 'Timeliness metrics such as delivery on time, speed of response to enquiries, speed of response to complaints etc.',
            value: 25,
          },
          {
            name: 'None of the above.',
            value: 0,
          },
        ],
      },
      {
        qtxt: "Evaluate how well you have defined your marketing objectives and how KPI's (success-failure) will be measured. Please select the statement that best describes your company:",
        q: '5c',
        questions: [
          {
            name: "Our goals and KPI's/ metrics are not properly identified.",
            value: 25,
          },
          {
            name: "We focus on short-term campaign KPI's",
            value: 50,
          },
          {
            name: 'We focus on a combination of campaign-related and customer-related metrics based on a plan and analyse regularly our data to measure effectiveness.',
            value: 75,
          },
          {
            name: "We use a scorecard to pull data sources into one dashboard for tracking and analysis and deploy various established metrics (mentioned before at Metrics) as KPI's.",
            value: 100,
          },
        ],
      },
      {
        qtxt: 'Audit the availability and use of your marketing technology to drive performance. Please select all that apply.',
        q: '5d',
        questions: [
          {
            name: 'Contacts. Databases and Lead generation/ nurturing - sales enablement tools.',
            value: 20,
          },
          {
            name: 'Connections - Visitors. Social, Website, email, interactive and remarketing software & tools.',
            value: 20,
          },
          {
            name: 'Content. Tools and software for content creation, optimization and scoring. ',
            value: 20,
          },
          {
            name: 'Customer Data. Go-To-Market operation solutions and relationship management software and analytics services.',
            value: 20,
          },
          {
            name: 'Use of Marketing Automation platform.',
            value: 20,
          },
        ],
      },
    ];
    this.sixthSection = [
      {
        qtxt: "Explore how your company's campaign plan is implemented and assess the phases that are regularly defined in your marketing campaign plan? Please select all that apply.",
        q: '6a',
        questions: [
          {
            name: "Analysis. Business needs, advanced segmentation with market's mapping, identification of key buying decision criteria and segments discovery & validation to target, insights & ideas. Definition of  strategy, priorities and actions to reach the buyer persona.",
            value: 15,
            id: 'inlineRadio1',
          },
          {
            name: 'Goal Setting. Business objectives, campaign goals and KPIs. (What are you trying to achieve through your campaign and how will you know when you achieve it?)',
            value: 15,
            id: 'inlineRadio2',
          },
          {
            name: 'Content. Creative and Key messaging. (How are you trying to position your company, products and services?) Campaign asset production. (Managing content and the assets to form the campaign.)',
            value: 15,
            id: 'inlineRadio3',
          },
          {
            name: 'Targeting. Campaigns are refined to address specific geographies, industries, growth markets, existing customers and channels. Super-targeting is used for campaign targeting (eg. with integrated CRM database, scoring mechanisms and advanced profiling with Buyer Personas.)',
            value: 15,
            id: 'inlineRadio3',
          },
          {
            name: 'Campaign media plan and budget (Which media channels would you use to reach and influence your target audience?)',
            value: 20,
            id: 'inlineRadio3',
          },
          {
            name: 'Execution. (What needs to be tested before the campaign is live and adjusted?)',
            value: 10,
            id: 'inlineRadio3',
          },
          {
            name: 'Tracking. Post execution assessment. Conduct a campaign analysis and modify to improve the overall performance if needed.',
            value: 10,
            id: 'inlineRadio3',
          },
          {
            name: 'None of the above.',
            value: 0,
            id: 'inlineRadio3',
          },
        ],
      },
      {
        qtxt: "Assess the level of your company's internal (strength & weakness) and external (threats-opportunities) environment. Please select the statement that best describes your company:",
        q: '6b',
        questions: [
          {
            name: 'There is no SWOT or competitive analysis recorded.',
            value: 25,
            id: 'inlineRadio4',
          },
          {
            name: 'We have SWOT analysis in progress.',
            value: 50,
            id: 'inlineRadio5',
          },
          {
            name: 'We have SWOT analysis  in place.',
            value: 75,
            id: 'inlineRadio6',
          },
          {
            name: 'The company has SWOT analysis available  along with other research findings for use in the marketing planning process.',
            value: 100,
            id: 'inlineRadio7',
          },
        ],
      },
      {
        qtxt: 'Audit and evaluate the value and marketability of your products/services? Please select all that apply.',
        q: '6c',
        questions: [
          {
            name: 'My company has products/services which have unique features that help them stand out or compete on price relative to perceived quality.',
            value: 20,
          },
          {
            name: 'We have products/services that customers are motivated to buy because they fulfill their needs.',
            value: 20,
          },
          {
            name: 'My company has defined ICP (Ideal Customer Profile) and personas and "who is it for".',
            value: 20,
          },
          {
            name: 'The value of our offerings to customer includes quality factors such as reliability,  customer experience, productivity, availability, usability etc.',
            value: 20,
          },
          {
            name: 'Our products/services can help customers to solve a problem that makes their life or work more convenient.',
            value: 20,
          },
          {
            name: 'None of the above.',
            value: 0,
          },
        ],
      },
      {
        qtxt: 'Analyze how your company budgets and proritize  the investment. Which of these are regularly are important and used in your marketing budget? Please select all that apply.',
        q: '6d',
        questions: [
          {
            name: 'Website            ',
            value: 20,
          },
          {
            name: 'Paid Media',
            value: 15,
          },
          {
            name: 'Social Media',
            value: 15,
          },
          {
            name: 'Content Creation',
            value: 10,
          },
          {
            name: 'Events/Conferences/Webinars',
            value: 10,
          },
          {
            name: 'Marketing Technology/Analytics',
            value: 10,
          },
          {
            name: 'Incentives/Other',
            value: 10,
          },
          {
            name: 'Corporate/Brand Building',
            value: 10,
          },
        ],
      },
    ];

    this.rec1 = [
      "Start taking more care of your company's presence to make it more extroverted. Use content and marketing assets  from your affiliates/partners / supplier vendors and create your own social media content that will slowly help you build your own social media channels (if you do not have any). Also consider to organize physical or digital events for customers and prospects.",
      'Further develop your brand identity through enriching your website content, making impact in digital and organizing succesful events, to meet the day-to-day needs of your business. Develop a marketing plan that suits and reflects your business by enhancing your branding, your products /solutions, while emphasizing on your customer value proposition. ',
      'You have a decent extroverted marketing presence that you need to develop furthermore by exploring solutions that make your company "more digital" and empower your products, the distribution channel, and the marketing content (eg. content syndication with your partner/vendors). Explore useful solutions and  applications or discuss with your marketing agency your further development in marketing .',
      'Enhance your already dynamic marketing presence by creating your own authentic content that will add value to your customers and explore deeper segmentation of your users/customers, so that you can tailor your content to the buyer personas and needs. Marketing Technology and consistent growth strategy empowers your marketing operations to prevail over your competitors and leverage a key competitive advantage.',
    ];
    this.rec2 = [
      "Planning and organizing the marketing department (or your company) will help you take the right steps to improve your position. Take advantage of this study's data, and discuss within your company how you can evolve to meet the demands of this new normality.",
      "Leverage this B2B Marketing Assessment framework and discuss your company's score with your key members, carving out the right B2B Marketing strategy for  the benefit of your company. Discourse about efficient marketing  campaigns within commercial and sales people to show them ROI and the need to align with a marketing 'necessity'. ",
      "Plan some marketing activities or campaigns that will demonstrate success and deliver against pre-defined metrics. These programs should be part of your long-term campaign plan. Monitor and maintain your business team's competency, knowledge and promptiness to adapt to new possibilities created by these campaigns. ",
      'Marketing should be considered as a substantial contributor to incremental revenue for the company. Continue to build a company-wide marketing and digital culture, enhancing your positioning, awareness and showing your customers that your are a modern company.',
    ];
    this.rec3 = [
      "Work urgently with your agency to define some essential marketing steps that build some stable pillars for your company's marketing.",
      'Εnsure that your marketing fundamentals elements  are all  aligned with your corporate, goals, business priorities and needs and move forwards by developing the next phases of your marketing plan.',
      "Move towards to leverage more your fundamental marketing elements. Drive further the analysis based on learnings from research and surveys.Work in alignment with your sales team to develop a deep understanding of your buyer behaviour through the customers’ online journey, so you can better evolve your company's customer value proposition, values and positioning vs your competitors.",
      'You are a sophisticated marketer with original content creation and your company has a stable marketing base. Enhance your already addresed marketing fundamentals essence by measuring your customer experience with extended surveys (adding new metrics) and developing  your own authentic content that will add value to your customers. Drive further campaign optimization efforts based on learnings from past campaigns and applying these learnings to the planning of future campaigns.',
    ];
    this.rec4 = [
      'Investigate and detect the conditions that favor the installation of a CRM platform in your company. For optimal results while adopting to it, try to involve the Sales and Marketing teams and let them contribute with ideas and ways for its proper utilization. ',
      'For optimal results while adopting to your CRM platform, try to involve the Sales and Marketing teams and let them contribute with ideas and ways for its proper utilization. Plan and define processes and workflows in both departments (Sales and Marketing) and integrate all other tools, even the manual processes you use, aiming to turn leads into opportunities. The customer journey should be the roadmap on what you need your CRM to do for you and initiate your CRM strategy.',
      'Sales-Marketing alignment and CRM are not plug-and-play tools that you simply add to business. They should be located at the core of your business. The good level of Sales-Marketing team alignment and the detailed recording of procedures in CRM lays the foundations for successful CRM function and adoption inside the company as the only way for sucessful lead generation and growth development. Set the limits of an automated process to control the evolution of your leads through concrete guidelines, shared between Sales and Marketing teams.',
      'Sales and Marketing Teams are fully aligned, leads qualification and scoring is fully automated and pipeline reporting is fully updated and automated. Thus the company earns higher order value from nurtured leads. Adoption and good use of your CRM platform will develop marketing streams that automatically nuture, upsell ,cross-sell and acquire customers, driving better business results.',
    ];
    this.rec5 = [
      'Benchmarking, metrics and analytics are important in business. Provide reference points against which you can measure your performance and a pre-requisite function to design a strategy and take decisions. Use metrics and analytics to evaluate the performance of your initiatives and drive ongoing improvements. Nowadays martech capabilities sould be considered as a neccessary tool for doing business and we all get to familiarize with this perespective.',
      'Try to adopt a marketing "currency" for your marketing campaigns. Define a set of metrics and marketing dashboards that will be the "currency" in your company for understanding planning, execution and performance results for your digital initiatives. Define metrics across topics such as audience segmentation, visitor classification, conversion rates, visual and creative comparative effectiveness, digital ROI, size, shape and velocity of the pipeline etc. It is crucial to enter to the new normality. ',
      "KPIs display a measurable value that shows the progress of your company’s business goals. Analytics can help you assess your company's  performance to ensure you continuously drive value and identify opportunities for improving performance. Track your progress through your measurable objectives and capitalize on them to make critical improvements and timely decisions as regards of your forthcoming plans. Your company is ready to move forward, based on a data-driven decision management. ",
      "Υou are already a data-driven company. Keep enhancing and explore your gained advantage to fully understand the buyers' behavior through the customer online journey, as well as to shape a current picture of the market trends. You will be in a favored position against your competitors regarding your business strategy and decision-making process.",
    ];

    this.rec6 = [
      'Engage with your business key members  and departments to reshape your current and future priorities, before start planning.',
      'Εnsure that your marketing plan, marketing operations and marketing activities are all aligned with your corporate goals, business priorities and needs. Involve your Sales and Business Developement team for leverage and further execution.',
      'Finding success, yet challenged with integration, your company is in the process of growth. Set forth a long-term campaign journey, aligning your brand strategy with far-reaching messaging and positioning. Consider upcoming industry trends, and ways to create campaigns meeting these expected new opportunities. You are now able to increase the budget of some campaigns, test their responsiveness and the revenue or the leads generation results. You may also consider increasing your marketing resources with additional colleagues or find an expert agency to help you.',
      'You are in healthy marketing position and your company has a stable marketing operations and campaigns planning level. Your company is in the process of growth. You are now able to increase the budget of some campaigns, test their responsiveness and the revenue or the leads generation results. Through a deeper understanding of customer behaviour, your company can move towards a predictive model where customer actions can be anticipated well before they actually happen. This allows you to deliver targeted messaging to sub-segments that are expected to behave in a predicted manner, while analysing the behaviour of the audience as a whole.',
    ];
  }
  // / constr

  returnScores() {
    const scores = [
      this.firstScore,
      this.secondScore,
      this.thirdScore,
      this.forthScore,
      this.fifthScore,
      this.sixthScore,
    ];
    return scores;
  }

  setFirstScore(score) {
    this.firstScore = score;
  }
  setSecondScore(score) {
    this.secondScore = score;
  }
  setThirdScore(score) {
    this.thirdScore = score;
  }
  setForthScore(score) {
    this.forthScore = score;
  }
  setFifthScore(score) {
    this.fifthScore = score;
  }
  setSixthScore(score) {
    this.sixthScore = score;
  }
}
